import {Injectable} from '@angular/core';
//
@Injectable({
	providedIn : 'root',
})
export class ServiceFileReader {
	constructor() {
		//
	}

	private static wipeFR( FR: FileReader ): void {
		FR.onload = null;
		FR.onerror = null;
		FR.onprogress = null;
	}

	public static getTextFileDataUTF8( fileObject: File, callback: (data: string | null) => void, progressCallback?: (percent: number) => void ): void {
		// fileObject = (HTMLInputElement).files[0]; // (or if you happen to have a File some other way, this can take it, too.)
		// not compatible with 2-byte chars. that's UTF-16
		if ( fileObject ) {
			const FR = new FileReader();
			let toggleLatch = false;
			//
			FR.onload = (E: ProgressEvent<FileReader>): void => {
				toggleLatch = true;
				this.wipeFR( FR );
				if ( E && E.target && E.target.result ) {
					if ( typeof E.target.result === 'string' ) {
						callback( E.target.result );
					} else if ( E.target.result.valueOf() instanceof ArrayBuffer ) {
						const TD = new TextDecoder(); // UTF-8 mode.
						callback( TD.decode( E.target.result ) );
					} else {
						callback( null );
					}
				} else {
					callback( null );
				}
			};
			//
			FR.onprogress = (E: ProgressEvent<FileReader>): void => {
				if ( typeof progressCallback === "function" ) {
					progressCallback( E.loaded / E.total );
				}
			};
			//
			FR.onerror = (E: ProgressEvent<FileReader>): void => {
				console.log( 'Failed to read a text file.', E );
				this.wipeFR( FR );
				if ( !toggleLatch ) {
					callback( null );
				}
			};
			//
			FR.readAsText( fileObject, 'utf-8' );
		} else {
			callback( null );
		}
	}
}
