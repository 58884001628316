import {Component, OnInit} from '@angular/core';
// ===== Components ===== //
import {ComponentDropDown} from '../drop-down';
//
@Component({
	selector: 'language-drop-down',
	templateUrl: '../drop-down.html',
	styleUrls: [
		'../drop-down.less'
	]
})
export class ComponentLanguageDropDown extends ComponentDropDown implements OnInit {
	//
	constructor() {
		super();
		this.items = [ // TODO: this should come from an API call
			'English', // english
			'Español', // spanish
			'Deutsche', // german
			'Français', // french
			'日本語', // japanese
			'中文', // chinese
			'한국어' // korean
		];
	}

	public override ngOnInit(): void {
		if ( typeof this.placeholderText !== 'string' || this.placeholderText.length < 1 ) {
			this.placeholderText = 'Choose Your Language';
		}
		this._onInit();
	}
}
