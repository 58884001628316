import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
//
@Injectable({
	providedIn : 'root',
})
export class ServiceNavigate {
	public constructor(
		private router: Router
	) {
		//
	}

	public toURL( url: string | string[] ): void {
		const destination = Array.isArray( url ) ? url.map( (fragment: string) => {
			if ( fragment.match( /^\// ) ) {
				return fragment;
			}
			return '/' + fragment;
		} ) : url;
		void this.router.navigate( [ destination ] ).then( (navSuccess) => {
			if ( !navSuccess ) {
				window.location.href = destination as string;
			}
		} );
	}
}
