import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../../../app.config';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWAPIAssetsFiles {
	private routePrefix = 'workspace/';
	//
	constructor(
		private auth: ServiceAuthentication,
		private config: AppConfig,
		private gateway: ServiceOWGateway
	) {
		//
	}

	private getFile( fileID: string, asBase64: boolean ): Observable<InterfaceHTTPGateway> {
		// grabbing files out of OW requires an auth token. so you will *not* be able to use them in img tags. (normally)
		return this.gateway.fetch( 'get', this.routePrefix + 'file/' + encodeURIComponent( fileID ) + (asBase64 ? '/b64' : '') );
	}

	// ========================= Files ========================= //

	public getFileAsBase64( fileID: string ): Observable<InterfaceHTTPGateway> {
		return this.getFile( fileID, true );
		// { "status" : 200 , "results" : [ { "image_data" : "data:image/png;base64,iVBORw0KGg..." } ] }
		// response.body.results[0].image_data //
	}
}
