import {Injectable} from '@angular/core';
import {BaseConfig} from '../../configs/base-config';
//
@Injectable({
	providedIn: 'root'
})
export class AppConfig extends BaseConfig {
	constructor() {
		super();
	}

	public getAppShortName(): string {
		return this.appShortName;
	}

	public getBaseAPI(): string {
		return this.baseAPI;
	}

	public getRealmID(): string {
		return this.realmID;
	}

	// public setWorkspaceID( id: string ): void {
	// 	this.workspaceID = id;
	// }

	public getWorkspaceID(): string {
		return this.workspaceID;
	}

	public getIOHost(): string {
		return this.ioHost;
	}

	public getTemplateID( name: string ): string {
		// console.log doesn't return anything, so it's possible to cheat using the nullish coalesce operator...
		// but only if browsers fire off console.log when the js inspector is open :S
		// return this.templateIDs[ name ] ?? console.log( 'Missing template ID for invalid name', name ) ?? '';
		return this.templateIDs[ name ] ?? '';
	}

	public getRoleID( name: string ): string {
		return this.roleIDs[name] ?? '';
	}

	public getSettingsID( name: string ): string {
		return this.settingsIDs[name] ?? '';
	}
}
