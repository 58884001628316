import {Injectable} from '@angular/core';
// ===== Collections ===== //
import {Collection} from './collection';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway, InterfaceOWAPIUserProfile, InterfaceOWUser} from '../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../services/authentication';
import {ServiceOWAPI} from '../services/ow-api';
import { typeAfterRequestForRecord, typeOptionallySuppressUpdate } from "./types/flow-control";
//
@Injectable({
	providedIn: 'root',
})
export class CollectionProfiles extends Collection {
	//
	constructor(
		private auth: ServiceAuthentication,
		private owapi: ServiceOWAPI
	) {
		super();
	}

	public cacheUserProfiles( data: InterfaceOWUser[] ): void {
		this.cacheRecords( data );
	}

	public fetchProfileByID( profileID: string, callback?: (userProfile: InterfaceOWUser | null) => any ): void {
		this.fetchRecordByID( profileID, (cbAfterRequestForRecord: typeAfterRequestForRecord): void => {
			this.owapi.account.profile.getUserProfile().subscribe( (response: InterfaceHTTPGateway) => {
				cbAfterRequestForRecord( (cbAfterProcessingRecord: typeOptionallySuppressUpdate): void => {
					let didUpdate = false;
					if ( response && response.success && response.status === 200 ) {
						let apiResponse: InterfaceOWAPIUserProfile = response.data;
						if ( apiResponse && apiResponse.data ) {
							this.cacheUserProfiles( [ apiResponse.data ] );
							didUpdate = true;
						}
					}
					cbAfterProcessingRecord( didUpdate );
				} );
			} );
		}, callback );
	}

	public getMyUserProfile( callback?: (userProfile: InterfaceOWUser | null) => any ): void {
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			if ( typeof callback === 'function' ) {
				callback( null ); // user is not signed in.
			}
		} else {
			const myProfile: InterfaceOWUser | null = this.getCachedRecordByID( profileID );
			if ( myProfile ) {
				if ( typeof callback === 'function' ) {
					callback( myProfile );
				}
			} else {
				this.fetchProfileByID( profileID, callback );
			}
		} // end else we have a profileID to work on.
	}
}
