import {Injectable} from '@angular/core';
// ===== Interfaces ===== //
interface InterfaceCSVParserOptions {
	delimiter?: ',' | string; // field separator/delimiter.
	enclosure?: '"' | string; // field "enclosures".
}
//
@Injectable({
	providedIn: 'root'
})
export class TransformerCSV {
	public constructor() {
		//
	}

	public static strArrayFromCSV( strCSVData: string, options?: InterfaceCSVParserOptions ): string[][] {
		if ( strCSVData.length < 1 ) {
			return [];
		}
		let d = ',';
		let q = '"';
		if ( options ) {
			d = options.delimiter ?? ',';
			q = options.enclosure ?? '"';
		}
		// for each char, look for the first non white-space or (quote) and make that the fieldStart
		const output: string[][] = []; // a table. an array of rows. inside each row is just an array of columns.
		let columns: string[] = []; // this goes into the output. a row is just an array of columns.
		let inBetweenCells: boolean = true; // true when we're hunting down the next delimiter or newlines or similar.
		let lookingForNextField = true; // true when we reached the delimiter while we are in between cells.
		let fieldStart: number = 0; // field-end will be [x - 1] when we find an ending enclosure (quote) or a delimiter (comma) on [x].
		let isFieldEnclosed: boolean = false;
		for ( let x: number = 0; x < strCSVData.length; ++x ) {
			if ( inBetweenCells ) {
				// bug -- just finished a row, left the \r\n at the end, now it found it, and then now it's adding an empty cell onto the new array of columns.
				if ( strCSVData[x].match( /[\r\n]/ ) ) { // if the line/row is terminated.
					const newline = strCSVData[x];
					output.push( columns );
					isFieldEnclosed = false;
					lookingForNextField = true; // true because we're about to start the next row of columns.
					columns = []; // do not make "columns" const. do not clear this using .splice, or the output will be filled with empty arrays. (storing ref's)
					if ( x + 1 < strCSVData.length ) { // check if we need to move past a "\r\n".
						if ( strCSVData[x + 1].match( /[\r\n]/ ) ) {
							if ( strCSVData[x + 1] === newline ) { // if it "\r" and "\r" or if it "\n" and "\n".
								// next row is a blank row. do nothing. (next loop takes care of it already)
							} else { // else it's one of \r or \n, but not the same: \r\r nor \n\n.
								// linux: \n
								// mac: \r
								// win: \r\n
								// wrong: \n\r
								// ...going to treat it like it's windows. meh.
								++x;
							}
						} // else the next char belongs to the next row, and is not still part of the newline of the current row.
					} // else we are on the last char.
					// not going to add a blank row the dataset because the file ended in a blank line...
				} else if ( strCSVData[x] === d ) { // if the char is the delimiter (comma)
					if ( lookingForNextField ) {
						// didn't find the next field, only found another delimiter.
						columns.push( strCSVData.slice( fieldStart, x ) ); // push the whole field in as data... may be blank. may be just spaces.
					}
					lookingForNextField = true; // now that we're looking for the next field, if the line ends with a newline, then we need to start a new row.
					fieldStart = x + 1; // if there is no enclosure char (quote) then we need to take the data fight after the delimiter (comma)
					// now that we found the delimiter, we need to find where the next field really starts...
					while ( x + 1 < strCSVData.length && strCSVData[x + 1].match( /[\t ]/ ) ) {
						++x; // skipping over spaces and tabs.
					}
					// next iteration (x + 1) could be any char, except a space or a tab..
				} else if ( strCSVData[x] === q ) { // if the char is an enclosure (quote)
					inBetweenCells = false;
					fieldStart = x + 1;
					isFieldEnclosed = true;
					// and we're in field delim mode?
				} else if ( lookingForNextField ) {
					// not a delimiter, not an enclosure, not a newline. must be data...
					inBetweenCells = false;
					fieldStart = x;
					isFieldEnclosed = false;
				}
			} else { // else we are not in between cells.
				if ( isFieldEnclosed ) {
					if ( strCSVData[x] === q ) { // if this char is a field enclosure (quote).
						if ( x + 1 < strCSVData.length && strCSVData[x + 1] === q ) {
							// found an escaped enclosing char.
							strCSVData = strCSVData.slice( 0, x ) + strCSVData.slice( x + 1 ); // just remove it from the data. x shouldn't change, either.
						} else {
							inBetweenCells = true;
							lookingForNextField = false; // false because we haven't found the next field delimiter that says there ought to be a next data-cell.
							columns.push( strCSVData.slice( fieldStart, x ) );
							isFieldEnclosed = false;
							lookingForNextField = false;
							// the other logic needs to determine where the next fieldStart ought to be...
						}
					} // else we didn't find the ending-enclosure yet.
				} else { // else the field is not enclosed so we are going until we find the next delimiter, or the end of the row.
					// if this is the last cell we are working on, then there won't be a final delimiter...
					if ( strCSVData[x].match( /[\r\n]/ ) ) { // row terminator. it's not inside quotes, so it can't be used as data...
						const newline = strCSVData[x];
						inBetweenCells = true;
						columns.push( strCSVData.slice( fieldStart, x ) );
						output.push( columns );
						columns = [];
						fieldStart = x;
						isFieldEnclosed = false;
						lookingForNextField = true; // true because we're about to start the next row of columns.
						if ( x + 1 < strCSVData.length ) {
							if ( strCSVData[x + 1].match( /[\r\n]/ ) ) {
								if ( strCSVData[x + 1] === newline ) { // the same newline char...
									// if the next char tells us that it's a simple blank line (vs the pair of \r\n which means we need to do more clean-up)
									// then do nothing, other logic will handle this.
								} else {
									++x; // move forward off of the \r\n.
								}
							}
						}
						//
					} else if ( strCSVData[x] === d ) { // if this char is a delimiter (comma)
						inBetweenCells = true;
						columns.push( strCSVData.slice( fieldStart, x-- ) ); // x-- because the delimiter is stuff in between cells.
						fieldStart = x + 1;
						isFieldEnclosed = false;
						lookingForNextField = false;
					} else if ( x === strCSVData.length - 1 ) { // if this is the last char in the file...
						inBetweenCells = true; // past the last data cell...
						columns.push( strCSVData.slice( fieldStart, x + 1 ) ); // x + 1 is past the char to keep: .slice( startingHere, beforeThis );
						isFieldEnclosed = false;
						lookingForNextField = false;
					} // end if this is the last char in the file.
				} // end else the field is not enclosed.
			} // end else we are not in between cells.
		} // end for each char to process.
		//
		// if fieldStart is somewhere not past the end of the file, prolly should do one last columns.push( strCSVData.slice( fieldStart ) ); ???
		//
		if ( columns.length > 0 ) {
			output.push( columns );
			columns = [];
		}
		return output;
		/*
		strCSVData = strCSVData.replace( /\r\n/g, '\n' ).replace( /\n+/g, '\n' );
		const lines: string[] = strCSVData.split( /\n/g );
		while ( lines.length > 0 && lines[ lines.length - 1 ].length < 1 ) {
			lines.pop();
		}
		const output: string[][] = [];
		for ( let x: number = 0; x < lines.length; ++x ) {
			// trim any white-space off the front/back of the line. this makes things unfriendly to .TSV files. especially with blank cells at the front.
			lines[x] = lines[x].replace( /^\s+|\s+$/g, '' ); // potential bug. if the data to use on the first/last cells, were intentionally only white-spaces, we've just deleted it.
			// need to be mindful that there may be white-space after the comma, or in between fields, etc.
			// double-quotes can be escape by adding a double-quote in front of it.  'hello"' => 'hello""'
			const columns: string[] = [];
			let isFieldEnclosed: boolean = lines[x].length > 0 && lines[x][0] === q;
			let fieldStart: number = isFieldEnclosed ? 1 : 0;
			let inFieldMode = true; // false when the character-scanning is in between cells (or enclosures), and we're looking for a delimiter while dealing with potential white-space.
			for ( let y: number = fieldStart; y < lines[x].length; ++y ) {
				if ( inFieldMode ) { // if we're trying to find the ending delimiter...
					if ( isFieldEnclosed ) {
						if ( lines[x][y] === q ) { // if we think we found it (might only be an escaped delimiter)
							if ( y + 1 < lines[x].length && lines[x][y + 1] === q ) { // if this was an escaped quote...
								lines[x] = lines[x].slice( 0, y ) + lines[x].slice( y + 1 ); // basically erase the char (the escape) from the string.
							} else {
								inFieldMode = false;
								// .slice( startFrom, stopBefore )
								columns.push( lines[x].slice( fieldStart, y ) ); // [y] === '"'
							}
						} // else we didn't find the enclosure yet...
					} else { // else the field is not enclosed (with quotes) so we're going until we hit the field delimiter (comma)
						// if this is the last cell, there won't be a final enclosure character.
						if ( lines[x][y] === d ) {
							inFieldMode = false;
							columns.push( lines[x].slice( fieldStart, y-- ) ); // y-- because [y] needs to be on char's in between cells on the next iteration.
						} else if ( y === lines[x].length - 1 ) { // else if we are on the last char of the line.
							inFieldMode = false;
							columns.push( lines[x].slice( fieldStart, y + 1 ) );
						}
					}
				} else { // else we are in between data-cells.
					// at this point, lines[x][y] should be not sitting on a character for the next data-cell. (if it was, you needed to {y--} to fix it)
					while ( y < lines[x].length && lines[x][y].match( /[\t ]/ ) ) { // move past any white-space...
						++y;
					}
					if ( y < lines[x].length ) {
						if ( lines[x][y] === d ) { // found the comma... but we need to find the enclosure char still...
							++y;
							fieldStart = y; // if we don't find an enclosure char, we start taking data from 1 past the comma.
							if ( lines[x].length === y ) { // did we just hit the end of the line from ++y ??
								// we found a comma, but there was no further data...
								columns.push( '' ); // so push an empty cell onto the list.
							}
						}
						while ( y < lines[x].length && lines[x][y].match( /[\t ]/ ) ) { // move past any white-space...
							++y;
						}
						if ( y < lines[x].length && lines[x][y] === q ) {
							fieldStart = y + 1; // the character after the quote.
							isFieldEnclosed = true;
						} else { // else we hit a non-white-space char and it wasn't the enclosure char (quote). meaning the white-space mayyyy be part of the data...
							isFieldEnclosed = false;
							fieldStart = y--;
						}
						inFieldMode = true;
					}
				}
			} // end for each row.
			output.push( columns );
		}
		return output;
		*/
	}
}
