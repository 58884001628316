import {Injectable} from '@angular/core';
// ===== Collections ===== //
import {Collection} from './collection';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway, InterfaceOWAPITemplateResponse, InterfaceOWAPITemplatesResponse, InterfaceOWTemplate} from '../interfaces/interfaces';
// ===== Services ===== //
import {ServiceOWAPI} from '../services/ow-api';
// ===== Types ===== //
import {typeAfterRequestForRecord, typeOptionallySuppressUpdate} from './types/flow-control';
//
@Injectable({
	providedIn: 'root',
})
export class CollectionTemplates extends Collection {
	constructor(
		private owapi: ServiceOWAPI
	) {
		super();
	}

	public cacheTemplates( templates: InterfaceOWTemplate[] ): void {
		this.cacheRecords( templates );
	}

	public fetchTemplateByID( templateID: string, callback: (template: InterfaceOWTemplate | null) => any ): void {
		this.fetchRecordByID( templateID, (cbAfterRequestForRecord: typeAfterRequestForRecord): void => {
			this.owapi.workspace.templates.getTemplateByID( templateID ).subscribe( (response: InterfaceHTTPGateway): void => {
				cbAfterRequestForRecord( (cbAfterProcessingRecord: typeOptionallySuppressUpdate): void => {
					if ( response && response.success && response.status === 200 ) {
						const apiResponse: InterfaceOWAPITemplateResponse = response.data;
						if ( apiResponse && apiResponse.data ) {
							this.cacheTemplates( [ apiResponse.data ] );
						}
						console.log( this.records );
					}
					cbAfterProcessingRecord();
				} );
			} );
		}, callback );
	}

	public fetchTemplates( callback?: (templates: InterfaceOWTemplate[]) => any ): void {
		const key = '__all__templates__';
		let cb = undefined;
		if ( typeof callback === 'function' ) {
			cb = (): void => {  // the underlying code will look for an _id of '__all__templates__', and won't find it when it gathers the records from the cache.
				callback( this.getCachedRecords() ); // ...so this will replace that missing functionality...
			};
		}
		this.fetchRecordByID( key, (cbAfterRequestForRecord: typeAfterRequestForRecord): void => {
			this.owapi.workspace.templates.getTemplates().subscribe( (response: InterfaceHTTPGateway): void => {
				cbAfterRequestForRecord( (cbAfterProcessingRecord: typeOptionallySuppressUpdate): void => {
					if ( response && response.success && response.status === 200 ) {
						const apiResponse: InterfaceOWAPITemplatesResponse = response.data;
						if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
							this.cacheTemplates( apiResponse.data.items );
						}
					}
					cbAfterProcessingRecord();
				} );
			} );
		}, cb );
	}
}
