import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../../../app.config';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWAPIMessageChannels {
	private routePrefix = 'workspace/';
	//
	constructor(
		private auth: ServiceAuthentication,
		private config: AppConfig,
		private gateway: ServiceOWGateway
	) {
		//
	}

	// ========================= Channels, Messages ========================= //

	public fetchChatChannels(): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + 'channels' );
		// { 'status' : 200, 'results' : [ { 'items' : [ { '_id' : { '$oid' : '5f6334765e0e3c3a035f5fbf' }, 'title' : '', 'description' : 'Private channel', 'private' : true, 'members' : [ { 'profile_id' : { '$oid' : '5e3fde1e5e3e5def5bae2180' }, 'status' : 'active' }, { 'profile_id' : { '$oid' : '5eeb8d144e0e5c778d55fbbb' }, 'status' : 'active' } ] } ] } ] }
	}

	public fetchChatChannel( channelID: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + 'channel/' + encodeURIComponent( channelID ) );
	}

	public getMyChatChannels(): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + 'channels' );
	}

	public getChatChannelMessages( channelID: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + 'channel/' + encodeURIComponent( channelID ) );
	}
}
