import {Component, OnInit} from '@angular/core';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppEvents} from '../../app.events';
import {AppRouterLinks} from '../../app.router-links';
// ===== Interfaces ===== //
import {
	InterfaceHTTPGateway, InterfaceOWAPIResponse, InterfaceOWDoclet
} from '../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceNavigate} from '../../services/navigate';
import {ServiceOWAPI} from '../../services/ow-api';
//
@Component({
	selector: 'page-visits',
	templateUrl: './visits.html',
	styleUrls: [
		'./visits.less'
	]
})
export class PageVisits implements OnInit {
	public visits: InterfaceOWDoclet[] = [];
	public isSignedIn: boolean = false;
	//
	constructor(
		private appEvents: AppEvents,
		private auth: ServiceAuthentication,
		private config: AppConfig,
		private nav: ServiceNavigate,
		private owapi: ServiceOWAPI
	) {
		//
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			this.fetchVisits();
		} else {
			this.nav.toURL( AppRouterLinks.signIn );
		}
	}

	public trackByVisitID( arrIdx: number, visit: InterfaceOWDoclet ): string {
		return visit._id.$oid; // TODO: get the real visit ID somehow?
	}

	private fetchVisits(): void {
		let q = 'limit:50'; // TODO: skip (offset), limit, sort
		this.owapi.workspace.doclets.getDocletsByTemplateID( this.config.getTemplateID( 'Patient Visit' ), q ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success ) {
				const apiResponse: InterfaceOWAPIResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
					this.visits = apiResponse.data.items.map( (record: InterfaceOWDoclet): InterfaceOWDoclet => {
						record.data['_icd10codes'] = record.data['icd10_code'].join( ', ' );
						return record;
					} );
				}
			}
		} );
	}

	public getVisitID( visit: InterfaceOWDoclet ): string {
		return visit._id.$oid; // TODO: better visit ID?
	}
}
