import {Injectable} from '@angular/core';
// ===== App ===== //
import {AppConfig} from '../../app.config';
// ===== Interfaces ===== //
import {
	InterfaceAnyObject, InterfaceCellPositions, InterfaceOWAPICreateDocletPayload,
	InterfaceOWTemplatePatient, InterfaceOWTemplatePatientVisit
} from '../../interfaces/interfaces';
interface InterfacePatientWithVisit {
	patient: InterfaceOWAPICreateDocletPayload;
	visit: InterfaceOWAPICreateDocletPayload;
}
// ===== Services ===== //
import {ServiceRegex} from '../../services/regex';
//
@Injectable({
	providedIn: 'root'
})
export class TransformerMediTextDataImporter {
	public constructor(
		private config: AppConfig,
		private regX: ServiceRegex
	) {
		// TODO: drag in a CSV file, transform it into arrays of arrays (table of data)
		// and then do something with it to make doclets of "Patients", "Patient Visits", etc.
	}

	private cellPositionConfigs: InterfaceCellPositions = {
		patientWithVisit: {
			patient: {
				mrn: 0,
				first_name: 1,
				last_name: 2,
				dob: 3,
				address: 4,
				city: 5,
				state: 6,
				zip: 7,
				cell_phone: 8,
				home_phone: 9,
				work_phone: 10,
				opt_out: 11,
				gender: 12
			},
			visit: {
				account_number: 13,
				chief_complaint: 14,
				diagnosis: 15,
				icd10_code: 16,
				visit_date: 17
			}
		}
	};

	public CSVDataToPatientAndVisit( csvData: string[][], cellPositions: InterfaceCellPositions['patientWithVisit'] ): InterfacePatientWithVisit[] {
		//
		// ----- pair up the visits and patients.
		// return all the junk as pairs.
		//
		const output: {
			patient: InterfaceOWAPICreateDocletPayload,
			visit: InterfaceOWAPICreateDocletPayload
		}[] = []; // TODO: something fancy to check if patient exists by MRN...
		const templateIdPatient = this.config.getTemplateID( 'Patient' );
		const templateIdPatientVisit = this.config.getTemplateID( 'Patient Visit' );
		console.log( templateIdPatient, templateIdPatientVisit );
		for ( let rowIdx: number = 0; rowIdx < csvData.length; ++rowIdx ) {
			let row: string[] = csvData[rowIdx];
			const createDocletPatient: InterfaceOWAPICreateDocletPayload = {
				data: {},
				tags: [],
				template_id: templateIdPatient,
				title: ''
			};
			const pKeys: (keyof InterfaceCellPositions['patientWithVisit']['patient'])[] = Object.keys( this.cellPositionConfigs.patientWithVisit.patient ) as (keyof InterfaceCellPositions['patientWithVisit']['patient'])[];
			for ( let x: number = 0; x < pKeys.length; ++x ) {
				const cellPosition = cellPositions?.patient?.[ pKeys[x] ] ?? this.cellPositionConfigs.patientWithVisit.patient[ pKeys[x] ];
				if ( typeof cellPosition === 'number' && cellPosition > -1 && cellPosition < row.length ) {
					createDocletPatient.data[ pKeys[x] ] = row[cellPosition];
				}
			}
			createDocletPatient.title = String( createDocletPatient.data['first_name'] + ' ' + createDocletPatient.data['last_name'] ).replace( this.regX.trimRegExp, '' ).toUpperCase();
			const createDocletPatientVisit: InterfaceOWAPICreateDocletPayload = {
				data: {},
				tags: [],
				template_id: templateIdPatientVisit,
				title: ''
			};
			const vKeys: (keyof InterfaceCellPositions['patientWithVisit']['visit'])[] = Object.keys( this.cellPositionConfigs.patientWithVisit.visit ) as (keyof InterfaceCellPositions['patientWithVisit']['visit'])[];
			for ( let x: number = 0; x < vKeys.length; ++x ) {
				const cellPosition = cellPositions?.visit?.[ vKeys[x] ] ?? this.cellPositionConfigs.patientWithVisit.visit[ vKeys[x] ];
				if ( typeof cellPosition === 'number' && cellPosition > -1 && cellPosition < row.length ) {
					createDocletPatientVisit.data[ vKeys[x] ] = row[cellPosition];
				}
			}
			output.push( {
				patient: createDocletPatient,
				visit: createDocletPatientVisit
			} );
			// create the two doclets? //
		} // end for each row of the CSVData
		return output;
	}
}
