import {Injectable} from '@angular/core';
// ===== Services ===== //
import {ServiceOWAPIWorkspaceActions} from './workspace/actions';
import {ServiceOWAPIWorkspaceDoclets} from './workspace/doclets';
import {ServiceOWAPIWorkspaceSettings} from "./workspace/settings";
import {ServiceOWAPIWorkspaceTasks} from './workspace/tasks';
import {ServiceOWAPIWorkspaceTemplates} from './workspace/templates';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWAPIWorkspace {
	private routePrefix = 'workspace/';
	//
	constructor(
		public actions: ServiceOWAPIWorkspaceActions,
		public doclets: ServiceOWAPIWorkspaceDoclets,
		public settings: ServiceOWAPIWorkspaceSettings,
		public tasks: ServiceOWAPIWorkspaceTasks,
		public templates: ServiceOWAPIWorkspaceTemplates
	) {
		//
	}
}
