import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../../../app.config';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWAPIAccountAuth {
	private routePrefix = 'account/';
	//
	constructor(
		private auth: ServiceAuthentication,
		private config: AppConfig,
		private gateway: ServiceOWGateway
	) {
		//
	}

	public validateAuthToken(): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + 'auth' );
	}

	public login( email: string, password: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + 'auth', JSON.stringify( {
			realm_id: this.config.getRealmID(),
			auth_params: {
				username: email.toLowerCase(),
				password: password
			}
		} ), true );
	}

	public updatePassword( email: string, oldPassword: string, newPassword: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + 'auth/password', JSON.stringify( {
			profile_id: this.auth.getProfileID(),
			realm_id: this.config.getRealmID(),
			email: email,
			old_password: oldPassword,
			password: newPassword
		} ) );
		// returns status code 417 if the old password is wrong. returns status 400 if the password is incorrect.
	}

	public redeemPasswordResetToken( token: string, newPassword: string ): Observable<InterfaceHTTPGateway> {
		if ( token.length > 0 && newPassword.length > 7 ) {
			return this.gateway.fetch( 'post', this.routePrefix + 'auth/password/reset/' + encodeURIComponent( token ), JSON.stringify( {
				password: newPassword
			} ), true );
		} else {
			return this.gateway.requestDenied();
		}
		// returns 200 on success. 400 if the token is bad.
	}
}
