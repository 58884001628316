import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http'; // https://angular.io/api/common/http/HttpClient
import {Injectable} from '@angular/core';
import {catchError, of, map, Observable} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../../app.config';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway, InterfaceHTTPMethods} from '../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../authentication';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWGateway {
	constructor(
		private auth: ServiceAuthentication,
		private config: AppConfig,
		private http: HttpClient
	) {
		//
	}

	public fetch( method: keyof InterfaceHTTPMethods, route: string, payload ?: string, noAuth ?: boolean ): Observable<InterfaceHTTPGateway> {
		let httpHeaders = new HttpHeaders().set( 'Content-Type', 'application/json' );
		if ( noAuth ) {
			// don't include the JWT in the header.
		} else {
			const JWT = this.auth.getAuthToken();
			if ( typeof JWT === 'string' && JWT.length > 0 ) { // if it's blank, let it stay blank and fail the authentication.
				httpHeaders = httpHeaders.set( 'Authorization', 'Bearer ' + JWT );
			} else {
				console.log( 'Rejected network request due to missing JWT' );
				return of( {
					success: false,
					status: 0,
					data: null
				} );
			}
		}
		const httpOptions = {
			observe: 'response', // documentation & libraries says 'body' is the only valid value, but that's not true. source code shows "body", "response" and "events".
			headers: httpHeaders
		};
		// GET    : (route, options)
		// POST   : (route, payload, options)
		// HEAD   : (route, options)
		// DELETE : (route, options)
		// PUT    : (route, payload, options)
		// PATCH  : (route, payload, options)
		// OPTIONS: (route, options)
		const param1 = this.config.getBaseAPI() + route;
		let param2; // = undefined;
		let param3; // = undefined;
		if ( method === 'post' || method === 'put' || method === 'patch' ) { // these methods have 3 parameters.
			param2 = payload ? payload : '';
			param3 = httpOptions;
		} else {
			param2 = httpOptions;
		}
		// @ts-ignore
		return this.http[method]( param1, param2, param3 ).pipe( // pipe returns an observable that returns data, once all parameters are processed.
			map( (response: any) => {
				return {
					success: response.hasOwnProperty( 'ok' ) ? !!response.ok : (Number( response.status ) >= 200 && Number( response.status ) < 300),
					status: Number( response.status ),
					data: response.body
				};
			} ),
			catchError( ( response: HttpErrorResponse ) => {
				// instead of headers, status and body
				// the response has headers, status and error. but it's the same data.
				return of( { // 'of()' returns an observable
					success: false,
					status: Number( response.status ),
					// 'data' will always become an array this way. should be an array of errors.
					data: response.error && Array.isArray( response.error.errors )
						? response.error.errors
						: (response.error && response.error.errors ? [ response.error.errors ] : [] )
				} );
			} )
		);
	}

	public requestDenied(): Observable<InterfaceHTTPGateway> {
		return of( {
			success: false,
			status: 0,
			data: null
		} );
	}

	private fetchContents( path: string ): Observable<InterfaceHTTPGateway> {
		return this.http.get( path, {
			observe: 'response'
		} ).pipe(
			map( ( response: any ) => {
				return {
					success: true,
					status: Number( response.status ),
					data: response.body // beware - hidden automatic-transformation steps may have already occurred.
				}; // if you request JSON files, data turns into an Object.
			} ),
			catchError( ( response: HttpErrorResponse ) => {
				return of( {
					success: false,
					status: Number( response.status ),
					data: response.error && Array.isArray( response.error.errors )
						? response.error.errors
						: (response.error && response.error.errors ? [ response.error.errors ] : [] )
				} );
			} )
		);
	}

	public fetchJSON( path: string ): Observable<InterfaceHTTPGateway> {
		if ( path && path.match( /\.json$/i ) !== null ) {
			return this.fetchContents( path );
		}
		return this.requestDenied();
	}
}
