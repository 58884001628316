<div id="wrapper-root">
	<app-header *ngIf="isSignedIn"></app-header>
	<div id="body-wrapper">
		<router-outlet></router-outlet>
	</div>

</div>
<div id="modal-view">
	<modal-sign-in *ngIf="showSignIn"></modal-sign-in>
</div>
<iframe id="download-target" name="download-target" style="background: none no-repeat scroll 0px 0px / cover transparent; border: 0px none transparent; height: 0px; left: 0px; line-height: 0px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; position: absolute; top: 0px; width: 0px; z-index: -1;"></iframe>
