import {Injectable} from '@angular/core';
// ===== Collections ===== //
import {Collection} from './collection';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway, InterfaceOWAPIDocletResponse, InterfaceOWAPIResponse, InterfaceOWDoclet} from '../interfaces/interfaces';
// ===== Services ===== //
import {ServiceOWAPI} from '../services/ow-api';
// ===== Types ===== //
import {typeAfterRequestForRecord, typeOptionallySuppressUpdate, typeProcessFetchedRecord, typeRequestForRecord} from './types/flow-control';
//
@Injectable({
	providedIn: 'root',
})
export class CollectionDoclets extends Collection {
	private pendingRequestsByTemplateID: {
		[templateID: string]: ( // key:value. value is a callback
			(doclets: InterfaceOWDoclet[]) => any
			)[]; // an array of functions
	} = {};
	//
	constructor(
		private owapi: ServiceOWAPI
	) {
		super();
	}

	public cacheDoclets( doclets: InterfaceOWDoclet[] ): void {
		this.cacheRecords( doclets );
	}

	private fetchRecordsByTemplateID( templateID: string, cbToAcquireRecords: typeRequestForRecord, callback?: (records: InterfaceOWDoclet[]) => any ): void {
		// internal function. see base class's fetchRecordByID(). this fetchRecordsByTemplateID() is just a variant.
		if ( !this.pendingRequestsByTemplateID.hasOwnProperty( templateID ) ) {
			this.pendingRequestsByTemplateID[templateID] = [];
		}
		if ( this.pendingRequestsByTemplateID[templateID].length > 0 ) {
			if ( typeof callback === 'function' ) {
				this.pendingRequestsByTemplateID[templateID].push( callback );
			}
		} else {
			const stackCookie = this.createStackCookie();
			this.stackCookies[templateID] = stackCookie;
			this.pendingRequestsByTemplateID[templateID].push( typeof callback === 'function' ? callback : this.stubFn );
			cbToAcquireRecords( (cbToProcessRecords: typeProcessFetchedRecord): void => {
				if ( this.stackCookies.hasOwnProperty( templateID ) && this.stackCookies[templateID] === stackCookie ) {
					this.stackCookies[templateID] = null;
					delete this.stackCookies[templateID];
					cbToProcessRecords( (suppressUpdatedEvent: boolean | undefined): void => {
						this.resolvePendingRequestByTemplateID( templateID );
						if ( !suppressUpdatedEvent ) {
							this.updated.emit();
						}
					} );
				}
			} );
		}
	}

	public getCachedDocletsByTemplateID( templateID: string | string[] ): InterfaceOWDoclet[] {
		const tIDs: string[] = Array.isArray( templateID ) ? templateID : [ templateID ];
		const output: InterfaceOWDoclet[] = [];
		const _ids: string[] = Object.keys( this.records );
		for ( let x = 0; x < _ids.length; ++x ) {
			if ( this.records[ _ids[x] ] && this.records[ _ids[x] ].template_id ) {
				for ( let y = 0; y < tIDs.length; ++y ) {
					if ( this.records[ _ids[x] ].template_id.$oid === tIDs[y] ) {
						output.push( this.records[ _ids[x] ] );
					}
				}
			}
		}
		return output;
	}

	private resolvePendingRequestByTemplateID( templateID: string ): void {
		if ( Array.isArray( this.pendingRequestsByTemplateID[templateID] ) && this.pendingRequestsByTemplateID[templateID].length > 0 ) {
			// aggregate doclets by a template, then send it through the matching callback.
			const output: InterfaceOWDoclet[] = this.getCachedDocletsByTemplateID( templateID );
			for ( let x = 0; x < this.pendingRequestsByTemplateID[templateID].length; ++x ) {
				if ( typeof this.pendingRequestsByTemplateID[templateID][x] === 'function' ) {
					this.pendingRequestsByTemplateID[templateID][x]( output );
				}
			} // end for each callback.
			this.pendingRequestsByTemplateID[templateID] = [];
		} // end if there are any callbacks to process.
	}

	public fetchDocletByID( docletID: string, callback: (doclet: InterfaceOWDoclet | null) => any ): void {
		this.fetchRecordByID( docletID, (cbAfterRequestForRecord: typeAfterRequestForRecord): void => {
			this.owapi.workspace.doclets.getDocletByID( docletID ).subscribe( (response: InterfaceHTTPGateway): void => {
				cbAfterRequestForRecord( (cbAfterProcessingRecord: typeOptionallySuppressUpdate): void => {
					if ( response && response.success && response.status === 200 ) {
						const apiResponse: InterfaceOWAPIDocletResponse = response.data;
						if ( apiResponse && apiResponse.data ) {
							this.cacheDoclets( [ apiResponse.data ] );
						}
					}
					cbAfterProcessingRecord();
				} );
			} );
		}, callback );
	}

	public fetchDocletsByTemplateID( templateID: string, callback?: (doclets: InterfaceOWDoclet[]) => any ): void {
		this.fetchRecordsByTemplateID( templateID, (cbAfterRequestForRecords: typeAfterRequestForRecord): void => {
			this.owapi.workspace.doclets.getDocletsByTemplateID( templateID ).subscribe( (response: InterfaceHTTPGateway): void => {
				cbAfterRequestForRecords( (cbAfterProcessingRecords: typeOptionallySuppressUpdate): void => {
					if ( response && response.success && response.status === 200 ) {
						const apiResponse: InterfaceOWAPIResponse = response.data;
						if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
							this.cacheRecords( apiResponse.data.items );
							// fail, this won't remove doclets from the cache, only add to it.
						}
					}
					cbAfterProcessingRecords();
				} );
			} );
		}, callback );
	}

	public override clear(): void {
		this.records = {};
		this.stackCookies = {};
		let byIDs = Object.keys( this.pendingRequestsByID );
		for ( let x = 0; x < byIDs.length; ++x ) {
			this.resolvePendingRequest( byIDs[x] );
		}
		byIDs = Object.keys( this.pendingRequestsByTemplateID );
		for ( let x = 0; x < byIDs.length; ++x ) {
			this.resolvePendingRequestByTemplateID( byIDs[x] );
		}
		this.updated.emit();
	}
}
