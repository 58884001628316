import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {InterfaceAppEvent} from './interfaces/interfaces';
//
@Injectable({
	providedIn: 'root'
})
export class AppEvents {
	private listeners: {
		[topic: string]: EventEmitter<InterfaceAppEvent>
	} = {};
	//
	public constructor() {
		//
	}

	public broadcast( event: InterfaceAppEvent | string ): void {
		if ( typeof event === 'string' ) {
			if ( this.listeners.hasOwnProperty( event ) ) {
				this.listeners[ event ].emit( {
					topic : event
				} );
			}
			// else it must be the interface.
		} else if ( this.listeners.hasOwnProperty( event.topic ) ) {
			( this.listeners[ event.topic ] as EventEmitter<InterfaceAppEvent> ).emit( event );
		}
	}

	public listen( topic: string ): Observable<InterfaceAppEvent> {
		// make sure you .unsubscribe(), from any observables your subscribed onto, or it'll leak memory.
		if ( !this.listeners.hasOwnProperty( topic ) ) {
			this.listeners[ topic ] = new EventEmitter<InterfaceAppEvent>();
		}
		return this.listeners[ topic ];
	}
}
