import {Component, OnInit} from '@angular/core';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Interfaces ===== //
import {
	InterfaceHTTPGateway, InterfaceMediTextSettingsICD10Appointments, InterfaceMediTextSettingsICD10Vitals, InterfaceMediTextSettingsICD10VitalsMatchMetrics, InterfaceOWResponse
} from '../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceNavigate} from '../../services/navigate';
import {ServiceOWAPI} from '../../services/ow-api';
//
@Component({
	selector: 'page-database',
	templateUrl: './database.html',
	styleUrls: [
		'./database.less'
	]
})
export class PageDatabase implements OnInit {
	public activeTab: 'appointments' | 'vitals' = 'appointments';
	public appointmentSettings: InterfaceMediTextSettingsICD10Appointments | undefined = undefined;
	public vitalsSettings: InterfaceMediTextSettingsICD10Vitals | undefined = undefined;
	//
	constructor(
		private appConfig: AppConfig,
		private auth: ServiceAuthentication,
		private nav: ServiceNavigate,
		private owapi: ServiceOWAPI
	) {
		//
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			// TODO: acquire statistics on ICD-10 codes seen thus far, from patient visits.
			// TODO: figure out if we need the same thing done for appointments. appointments aren't visits...
			this.owapi.workspace.settings.getSettingsByID( this.appConfig.getSettingsID( 'ICD10AppointmentMonths' ) ).subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response && response.success ) {
					const apiResponse: InterfaceOWResponse = response.data;
					if ( apiResponse && apiResponse.data ) {
						this.appointmentSettings = apiResponse.data;
					}
				}
			} );
			this.owapi.workspace.settings.getSettingsByID( this.appConfig.getSettingsID( 'ICD10Vitals' ) ).subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response && response.success ) {
					const apiResponse: InterfaceOWResponse = response.data;
					if ( apiResponse && apiResponse.data ) {
						this.vitalsSettings = apiResponse.data;
					}
				}
			} );
		} else {
			this.nav.toURL( AppRouterLinks.signIn );
		}
	}

	// ===== Appointment Settings ===== //
	private saveAppointmentSettings(): void {
		// TODO: we need a way to save the settings record, using the API
		// this.owapi.workspace.settings.saveSettingsSomehow( ... ).subscribe( ... )
	}

	public toggleAppointmentMatchType( idx: number ): void {
		if ( this.appointmentSettings && this.appointmentSettings.settings.icd10_match.length > idx ) {
			this.appointmentSettings.settings.icd10_match[idx].match_type = this.appointmentSettings.settings.icd10_match[idx].match_type === 'full' ? 'prefix' : 'full';
			console.log( 'TODO: save settings to DB -- match_type', this.appointmentSettings.settings.icd10_match[idx] );
			this.saveAppointmentSettings();
		}
	}

	public appointmentMonthsKeyDown( E: KeyboardEvent ): boolean { // enforcing input-type-number on type="text"
		return !E.key.toLowerCase().match( /^[\x20-\x2F\x3A-\x7E]$/ ); // not allowing printable char's that are A-Z or symbols. only digits. also allowing meta-keys. (backspace, shift, etc.)
	}

	public changeAppointmentMonths( idx: number, E: Event ): void {
		if ( this.appointmentSettings && this.appointmentSettings.settings.icd10_match.length > idx ) {
			const newVal: string = (E.target as HTMLInputElement).value.replace( /[\x20-\x2F\x3A-\x7E]+/, '' );
			(E.target as HTMLInputElement).value = String( parseInt( newVal, 10 ) );
			const amount: number = Number( newVal );
			if ( this.appointmentSettings.settings.icd10_match[idx].months !== amount ) {
				this.appointmentSettings.settings.icd10_match[idx].months = amount;
				console.log( 'TODO: save settings to DB -- months', this.appointmentSettings.settings.icd10_match[idx] );
				this.saveAppointmentSettings();
			}
		}
	}

	// ===== Vitals Settings ===== //
	private saveVitalsSettings(): void {
		// TODO: we need a way to save the settings record, using the API
		// this.owapi.workspace.settings.saveSettingsSomehow( ... ).subscribe( ... )
	}

	public toggleVitalsMatchType( idx: number ): void {
		if ( this.vitalsSettings && this.vitalsSettings.settings.icd10_match.length > idx ) {
			this.vitalsSettings.settings.icd10_match[idx].match_type = this.vitalsSettings.settings.icd10_match[idx].match_type === 'full' ? 'prefix' : 'full';
			console.log( 'TODO: save settings to DB -- match_type', this.vitalsSettings.settings.icd10_match[idx] );
			this.saveVitalsSettings();
		}
	}
	
	public toggleVitalsMetric( idx: number, metric: keyof InterfaceMediTextSettingsICD10VitalsMatchMetrics ): void {
		if ( this.vitalsSettings && this.vitalsSettings.settings.icd10_match.length > idx ) {
			this.vitalsSettings.settings.icd10_match[idx].vitals[metric] = !this.vitalsSettings.settings.icd10_match[idx].vitals[metric];
			console.log( 'TODO: save settings to DB -- ' + metric, this.vitalsSettings.settings.icd10_match[idx] );
			this.saveVitalsSettings();
		}
	}

	public blur( E: Event ): void { // typescript error: $event.target.blur is not a function
		(E.target as HTMLElement).blur(); // because $event.target is an unknown type... typescript fail.
	}
}
