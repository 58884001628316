import {Component} from '@angular/core';
// ===== Components ===== //
import {ComponentCheckBoxActionLink} from '../action-linked';
// ===== Events ===== //
import {AppEvents} from '../../../../../app.events';
//
@Component({
	selector: 'ui-check-box-terms-of-use',
	templateUrl: '../action-linked.html',
	styleUrls: [
		'../action-linked.less'
	]
})
export class ComponentCheckBoxTermsOfUse extends ComponentCheckBoxActionLink {
	//
	constructor(
		private appEvents: AppEvents
	) {
		super();
		this.actions = [
			{
				text: 'I have read and agree to the'
			},
			{
				text: 'Terms of Use',
				isLink : true
			},
			{
				text: '.',
				noNBSP: true
			}
		];
	}

	public override linkActivated( idx: number ): void {
		if ( idx === 1 ) {
			this.appEvents.broadcast( 'modal:open:terms-of-use' );
		}
		super.linkActivated( idx ); // TODO: ensure this won't run the parent-class's method twice. (does the parent also run it's code, too?)
	}
}
