<nav id="navigation">
	<img alt="Meditext" class="logo" src="assets/img/brand/horizontal%20light%20bg.png" />
	<ul class="nomobile">
		<li [routerLink]="'/' + routes.snapshot" routerLinkActive="active"><i class="fa-regular fa-compass"></i> Overview</li>
		<li [routerLink]="'/' + routes.appointments" routerLinkActive="active"><i class="fa-regular fa-calendar"></i> Schedule</li>
		<li [routerLink]="'/' + routes.patients" routerLinkActive="active"><i class="fa-regular fa-clipboard"></i> Patients</li>
		<li [routerLink]="'/' + routes.visits" routerLinkActive="active"><i class="fa-regular fa-clipboard"></i> Visits</li>
		<li [routerLink]="'/' + routes.messages" routerLinkActive="active"><i class="fa-regular fa-message"></i> Inbox</li>
		<li [routerLink]="'/' + routes.database" routerLinkActive="active"><i class="fa-regular fa-hard-drive"></i> Datasets</li>
		<!--<li [routerLink]="'/' + routes.settings" routerLinkActive="active" class="anchored"><i class="fa-regular fa-circle-dot"></i> Settings</li>-->
		<!--<li [routerLink]="'/' + routes.getHelp" routerLinkActive="active"><i class="fa-regular fa-life-ring"></i> Get Help</li>-->
	</ul>
	<ul class="utility">
		<li class="nomobile icon-text">{{ user?.data?.['first_name'] ?? '' }}&nbsp;{{ user?.data?.['last_name'] ?? '' }} <i class="fa-regular fa-user"></i></li>
		<li class="nomobile"><i class="fa-regular fa-bell"></i></li>
		<li><i class="fa-solid fa-bars"></i></li>
	</ul>
</nav>
