// ===== Components ===== //
import {ComponentAppHeader} from './app-header/app-header';
import {ComponentAppFooter} from './app-footer/app-footer';
import {ComponentCheckBox} from './inputs/check-box/check-box';
import {ComponentCheckBoxActionLink} from './inputs/check-box/action-linked/action-linked';
import {ComponentCheckBoxLiabilityWaiver} from './inputs/check-box/action-linked/liability-waiver/liability-waiver';
import {ComponentCheckBoxPrivacyPolicy} from './inputs/check-box/action-linked/privacy-policy/privacy-policy';
import {ComponentCheckBoxTermsOfUse} from './inputs/check-box/action-linked/terms-of-use/terms-of-use';
import {ComponentDropDown} from './inputs/drop-down/drop-down';
import {ComponentInput} from './inputs/input/input';
import {ComponentLabeledInput} from './inputs/input/labeled-input/labeled-input';
import {ComponentLanguageDropDown} from './inputs/drop-down/language-drop-down/language-drop-down';
import {ComponentNavFrame} from './nav-frame/nav-frame';
import {ComponentNavHorizontal} from './menus/nav-horizontal/nav-horizontal';
import {ComponentNavVertical} from './menus/nav-vertical/nav-vertical';
import {ComponentNotifications} from './notifications/notifications';
import {ComponentRadioGroup} from './inputs/radio-group/radio-group';
import {ComponentTimeZoneDropDown} from './inputs/drop-down/time-zone-drop-down/time-zone-drop-down';
import {ComponentToggleBox} from './inputs/toggle-box/toggle-box';
// ===== Modules ===== //
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
//
const components = [
	ComponentAppHeader,
	ComponentAppFooter,
	ComponentCheckBox,
	ComponentCheckBoxActionLink,
	ComponentCheckBoxLiabilityWaiver,
	ComponentCheckBoxPrivacyPolicy,
	ComponentCheckBoxTermsOfUse,
	ComponentDropDown,
	ComponentInput,
	ComponentLabeledInput,
	ComponentLanguageDropDown,
	ComponentNavFrame,
	ComponentNavHorizontal,
	ComponentNavVertical,
	ComponentNotifications,
	ComponentRadioGroup,
	ComponentTimeZoneDropDown,
	ComponentToggleBox
];
//
@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		FormsModule,
		RouterModule
	],
	providers: []
})
export class ModuleComponents {}
