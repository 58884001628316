import {Component, EventEmitter, Input, Output} from '@angular/core';
//
@Component({
	selector: 'ui-toggle-box',
	templateUrl: './toggle-box.html',
	styleUrls: [
		'./toggle-box.less'
	]
})
export class ComponentToggleBox {
	@Input() checked: boolean = false;
	@Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();
	//
	constructor() {
		// issue with using @Input is that when the parent comp changes it's value,
		// this comp has no idea and won't update.
		// seems angular checks by ref, so if you just change the value (true/false), it doesn't update.
	}

	public toggle(): void {
		this.checked = !this.checked;
		this.changed.emit( this.checked );
	}
}
