<nav id="navigation">
	<img alt="Meditext" class="logo" src="assets/img/brand/horizontal%20light%20bg.png"/>
</nav>
<div id="page-sign-in">
	<div class="form">
		<span class="title">Welcome Back</span>
		<span class="subtitle">Please sign in below</span>
		<label [class.invalid]="badEmail" [class.dirty]="email.length">
			<span class="sublabel">Email Address</span>
			<input type="text" name="email"
				[class.error]="badLogIn || badEmail"
				[(ngModel)]="email"
				[ngModelOptions]="{ updateOn : 'blur' }"
				(ngModelChange)="checkEmail();"
				(focus)="badLogIn = false; badEmail = false;"
				(blur)="checkEmail();"
				(keyup.enter)="blur( $event ); !busy && signIn();"
			/>
			<i class="fa-solid fa-at input-icon"></i>
		</label>
		<label [class.invalid]="badLogIn" [class.dirty]="password.length">
			<span class="sublabel">Password</span>
			<input type="password" name="password"
				[class.error]="badLogIn"
				[(ngModel)]="password"
				[ngModelOptions]="{ updateOn : 'blur' }"
				(focus)="badLogIn = false;"
				(keyup.enter)="blur( $event ); !busy && signIn();"
			/>
			<i class="fa-solid fa-key input-icon"></i>
		</label>
		<div class="actions">
			<span class="action button primary submit" (click)="!busy && signIn();">Log In</span>
			<span class="action button textonly">Account Recovery</span>
		</div>
	</div>
</div>
