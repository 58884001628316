import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
// ===== Services ===== //
import {ServiceRegex} from '../../../services/regex';
//
@Component({
	selector: 'app-input',
	templateUrl: './input.html',
	styleUrls: [
		'./input.less'
	]
})
export class ComponentInput implements OnInit {
	// =================================== //
	// don't use this. it's not ready yet. //
	// =================================== //
	@ViewChild( 'inp' ) input: ElementRef | undefined = undefined;
	@Input() type = 'text';
	@Input() validator: (() => boolean) | undefined = undefined; // optional function
	// ===== //
	// in order to do two-way ([binding]), you need to name the emitter with a suffix of 'Change'.
	// so it become whateverChange. then the parent component needs simply do ([value])="someVar".
	@Input() value: any; // the data-type depends on the input's this.type
	@Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
	// ===== //
	@Input() error = false;
	@Output() errorChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	// ===== //
	@Output() keyUpEnter: EventEmitter<void> = new EventEmitter<void>();
	//
	constructor(
		protected regX: ServiceRegex
	) {
		//
	}

	public ngOnInit(): void {
		if ( !(this.validator instanceof Function) ) {
			switch ( this.type ) {
				case 'password':
				case 'text': {
					this.validator = () => {
						return (this.value as string).length > 0;
					};
					break;
				}
				// number, date, color...
			}
		}
	}

	public validate(): void {
		let isOK = true;
		if ( this.validator && this.validator instanceof Function ) {
			isOK = this.validator();
		}
		this.errorChange.emit( !isOK );
	}

	public processChange(): void {
		if ( !this.input ) {
			return;
		}
		this.value = this.input.nativeElement.value;
		switch ( this.type ) {
			case 'text': {
				this.value = (this.value as string).replace( this.regX.trimRegExp, '' );
				break;
			}
		}
		this.validate();
		if ( this.validator && this.validator instanceof Function ) {
			this.validator();
		}
		if ( this.input ) {
			this.input.nativeElement.value = this.value;
		}
		this.valueChange.emit( this.value );
	}

	public keyUpReturn( E: Event): void {
		if ( E && E.target ) {
			(E.target as HTMLElement).blur();
		}
		this.keyUpEnter.emit();
	}
}
