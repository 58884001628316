import {Component, OnInit} from '@angular/core';
// ===== App ===== //
import {AppRouterLinks} from '../../app.router-links';
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceNavigate} from '../../services/navigate';
//
@Component({
	selector: 'page-get-help',
	templateUrl: './get-help.html',
	styleUrls: [
		'./get-help.less'
	]
})
export class PageGetHelp implements OnInit {
	//
	constructor(
		private auth: ServiceAuthentication,
		private nav: ServiceNavigate
	) {
		//
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			// do stuff
		} else {
			this.nav.toURL( AppRouterLinks.signIn );
		}
	}
}
