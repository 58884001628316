import {Component} from '@angular/core';
// ===== App ===== //
import {AppConfig} from '../../app.config';
// ===== Interfaces ===== //
import {
	InterfaceAnyObject, InterfaceCellPositions, InterfaceHTTPGateway, InterfaceOWAPICreateDocletPayload, InterfaceOWAPICreateDocletResponse, InterfaceOWAPICreateWeaveResponse, InterfaceOWAPIDocletResponse, InterfaceOWAPIResponse, InterfaceOWDoclet, InterfaceOWTemplatePatientVisit
} from '../../interfaces/interfaces';
interface InterfacePatientWithVisit {
	patient: InterfaceOWAPICreateDocletPayload;
	visit: InterfaceOWAPICreateDocletPayload;
}
// ===== Services ===== //
import {ServiceFileReader} from '../../services/file-reader';
import {ServiceOWAPI} from '../../services/ow-api';
import {ServiceRegex} from '../../services/regex';
// ===== Transformers ===== //
import {TransformerCSV} from '../../transformers/csv';
import {TransformerMediTextDataImporter} from '../../transformers/meditext/data-importer';
//
@Component({
	selector: 'page-data-import',
	templateUrl: './data-import.html',
	styleUrls: [
		'./data-import.less'
	]
})
export class PageDataImport { // temp component. the logic and things should be moved to where the button to manage data imports are going..
	//
	constructor(
		private config: AppConfig,
		private dataImporterTX: TransformerMediTextDataImporter,
		private owapi: ServiceOWAPI,
		private regX: ServiceRegex
	) {
		//
	}

	private useThis1st: InterfaceCellPositions['patientWithVisit'] = {
		patient: {
			mrn: 0,
			dob: 1,
			first_name: 2,
			last_name: 3,
			address: 5,
			home_phone: 6,
			work_phone: 7,
			cell_phone: 8,
			gender: 10,
			//
			city: -1, // it's in the address
			state: -1, // it's in the address
			zip: -1, // it's in the address
			opt_out: -1
		},
		visit: {
			chief_complaint: 12,
			diagnosis: 13,
			//
			account_number: -1,
			icd10_code: -1, // it's in the diag
			visit_date: -1 // it's in chief complaint
		}
	};

	private mrnToVisits: { [MRN: string]: InterfaceOWAPICreateDocletPayload[]; } = {};
	private mrnToPatientCSVData: { [MRN: string]: InterfacePatientWithVisit['patient']; } = {};
	private mrnToPatientDoclet: { [MRN: string]: InterfaceOWDoclet; } = {};
	private mrnToProcess: string[] = [];
	private busy: boolean = false; // true when we are processing a file...
	// have a for loop, will queue up entire dataset to create/fetch.

	private cycleItAgain(): void { // finished a patient + visits, starting on the next one...
		setTimeout( () => {
			// if you just call the fn directly, it makes the stack grow. this disconnects it and makes it start over.
			this.createPatient();
		}, 50 );
	}

	private createVisit( visitPayload: InterfaceOWAPICreateDocletPayload, callback: (docletID: string | null) => void ): void { // part 3 - prior to weaving the visit to a Patient.
		this.owapi.workspace.doclets.createDoclet(
			visitPayload.title,
			visitPayload.data,
			visitPayload.template_id,
			visitPayload.tags
		).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success ) {
				const apiResponse: InterfaceOWAPICreateDocletResponse = response.data;
				if ( apiResponse && apiResponse.data && apiResponse.data.doclet_id ) {
					callback( apiResponse.data.doclet_id );
				} else {
					console.log( 'Could not figure out Patient Visit doclet _id', response );
					callback( null );
				}
			} else {
				console.log( 'Failed to create a Patient Visit', response );
				callback( null );
			}
		} );
	}

	private processVisitsToPatient( mrn: string, callbackWhenNoVisitsRemaining: () => void ): void { // part 2 - after getting a Patient.
		if ( this.mrnToVisits[mrn].length > 0 ) { // if false, breaks out of the recursion...
			// ===== Create the Patient Visit ===== //
			this.createVisit( this.mrnToVisits[mrn].pop() as InterfaceOWAPICreateDocletPayload, (visitDocletID: string | null): void => {
				if ( typeof visitDocletID === 'string' && visitDocletID.length > 0 ) {
					// ===== Weave the Patient Visit to the Patient ===== //
					this.owapi.workspace.doclets.createWeave( this.mrnToPatientDoclet[mrn]._id.$oid, {}, visitDocletID ).subscribe( (response: InterfaceHTTPGateway): void => {
						if ( response && response.success ) {
							const apiResponse: InterfaceOWAPICreateWeaveResponse = response.data;
							if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
								// ===== Success ===== //
								console.log( 'Weaved a Patient Visit to a Patient', mrn + ' => ' + visitDocletID );
							} else { // else failed...
								console.log( 'Failed to check if a weave succeeded?!?', mrn + ' => ' + visitDocletID, response );
							}
						} else { // else failed...
							console.log( 'Failed to weave a Patient Visit to a Patient', mrn + ' => ' + visitDocletID, response );
						}
						// success or fail, we still have to move on and process the next iteration.
						this.processVisitsToPatient( mrn, callbackWhenNoVisitsRemaining );
					} );
				} else {
					console.log( 'visit doclet ID was not a string?!?', visitDocletID );
					this.processVisitsToPatient( mrn, callbackWhenNoVisitsRemaining );
				}
			} );
		} else {
			callbackWhenNoVisitsRemaining();
		}
	}

	private createPatient(): void { // part 1 - after file input.
		if ( this.mrnToProcess.length > 0 ) {
			const mrn: string = this.mrnToProcess.pop() as string;
			// try to fetch patient by MRN
			// create one, if missing.
			this.owapi.workspace.doclets.getDocletsByTemplateID( this.config.getTemplateID( 'Patient' ), 'data.mrn:' + mrn ).subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response && response.success ) {
					const apiResponse: InterfaceOWAPIResponse = response.data;
					const patients: InterfaceOWDoclet[] = apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ? apiResponse.data.items : [];
					let patientDoclet: InterfaceOWDoclet | undefined = undefined;
					if ( patients.length > 0 ) {
						patientDoclet = patients.pop();
					}
					if ( patientDoclet && patientDoclet._id ) {
						// else pass it to a fn that starts to weave visits to a patient.
						this.mrnToPatientDoclet[mrn] = patientDoclet;
						this.processVisitsToPatient( mrn, (): void => {
							this.cycleItAgain();
						} );
					} else {
						this.owapi.workspace.doclets.createDoclet(
							this.mrnToPatientCSVData[mrn].title,
							this.mrnToPatientCSVData[mrn].data,
							this.mrnToPatientCSVData[mrn].template_id,
							this.mrnToPatientCSVData[mrn].tags
						).subscribe( (response2: InterfaceHTTPGateway): void => {
							if ( response2 && response2.success ) {
								const apiResponse2: InterfaceOWAPICreateDocletResponse = response2.data;
								const patientID: string = apiResponse2.data.doclet_id;
								this.owapi.workspace.doclets.getDocletByID( patientID ).subscribe( (response3: InterfaceHTTPGateway): void => {
									let fail = true;
									if ( response3 && response3.success ) {
										const apiResponse3: InterfaceOWAPIDocletResponse = response3.data;
										if ( apiResponse3 && apiResponse3.data && apiResponse3.data._id ) {
											this.mrnToPatientDoclet[mrn] = apiResponse3.data;
											fail = false;
											this.processVisitsToPatient( mrn, (): void => {
												this.cycleItAgain(); // done with a patient + visits, start again.
											} );
										} else {
											console.log( 'API response error when fetching a Patient', response3 );
										}
									} else {
										console.log( 'Failed to fetch a Patient that was just created', patientID );
									}
									if ( fail ) {
										this.cycleItAgain(); // just keep going.
									}
								} );
							} else {
								console.log( 'Failed to create a new patient doclet', this.mrnToPatientCSVData[mrn], response2 );
								this.cycleItAgain();
							}
						} );
					}
					// look for doclet, or create a new patient.
				} else {
					console.log( 'Bad request when fetching patient by MRN', mrn, response );
					this.cycleItAgain();
				}
			} );
		} else {
			console.log( 'Completed the thing.' );
			this.busy = false;
			// clean-up...
			this.mrnToPatientCSVData = {};
			this.mrnToPatientDoclet = {};
			this.mrnToVisits = {};
		}
	}

	public processFileInput( E: Event ): void {
		const i: HTMLInputElement = E.target as HTMLInputElement;
		if ( i && i.files && i.files.length > 0 ) {
			ServiceFileReader.getTextFileDataUTF8( i.files[0], (strCSVData: string | null): void => {
				if ( this.busy ) { return; }
				this.busy = true;
				this.mrnToPatientDoclet = {};
				this.mrnToVisits = {};
				if ( typeof strCSVData === "string" ) {
					const arrCSVData: string[][] = TransformerCSV.strArrayFromCSV( strCSVData );
					const pvPairs: InterfacePatientWithVisit[] = this.dataImporterTX.CSVDataToPatientAndVisit( arrCSVData, this.useThis1st );
					// the pvPairs' visits has compound data, meaning there are multiple visits packed into the object, and needs to be split apart.
					for ( let x: number = 1; x < pvPairs.length; ++x ) {
						const mrn: string = pvPairs[x].patient.data['mrn'];
						if ( !Array.isArray( this.mrnToVisits[mrn] ) ) {
							this.mrnToVisits[mrn] = [];
						}
						if ( !this.mrnToPatientCSVData.hasOwnProperty( mrn ) ) {
							this.mrnToPatientCSVData[mrn] = pvPairs[x].patient;
						}
						// compound data. chief complaint and diagnosis have multiple values pertaining to many visits.
						// have to un-fuse them and turn them into an array of things to process.
						const arrChiefComplaint: string[] = pvPairs[x].visit.data?.['chief_complaint'].split( /,/g ) ?? [];
						const chiefComplaintDates: { [MMDDYY: string]: string; } = {};
						const diagnosisDates: { [MMDDYY: string]: string; } = {};
						const icdDates: { [MMDDYY: string]: string[]; } = {};
						let d: string = ''; // date str. YY/MM/DD
						// ===== Chief Complaint(s) ===== //
						for ( let y: number = 0; y < arrChiefComplaint.length; ++y ) {
							arrChiefComplaint[y] = arrChiefComplaint[y].replace( this.regX.trimRegExp, '' );
							if ( arrChiefComplaint[y].match( /^\d\d\/\d\d\/\d\d/ ) ) {
								d = arrChiefComplaint[y].slice( 0, 8 ); // YY/MM/DD
								arrChiefComplaint[y] = arrChiefComplaint[y].slice( 8 ).replace( /^[ \-\t]+/, '' );
							}
							if ( !chiefComplaintDates.hasOwnProperty( d ) ) {
								chiefComplaintDates[d] = '';
							}
							chiefComplaintDates[d] = chiefComplaintDates[d].length > 0 ? chiefComplaintDates[d] + ', ' + arrChiefComplaint[y] : arrChiefComplaint[y];
						} // end for each segment in the chief complaint.
						d = '';
						// ===== Diagnosis / ICD10 codes ===== //
						const arrDiagnosis: string[] = pvPairs[x].visit.data?.['diagnosis']?.split( /,/g ) ?? [];
						for ( let y: number = 0; y < arrDiagnosis.length; ++y ) {
							arrDiagnosis[y] = arrDiagnosis[y].replace( this.regX.trimRegExp, '' );
							if ( arrDiagnosis[y].match( /^\d\d\/\d\d\/\d\d/ ) ) {
								d = arrDiagnosis[y].slice( 0, 8 ); // "YY/MM/DD"
								arrDiagnosis[y] = arrDiagnosis[y].slice( 8 ).replace( /^[ \-\t]+/, '' );
							}
							if ( arrDiagnosis[y].match( /\[[a-z0-9\.]+\]$/i ) ) {
								let idx: number = -1;
								for ( let z = arrDiagnosis[y].length; z > -1; --z ) {
									if ( arrDiagnosis[y][z] === '[' ) {
										if ( !icdDates.hasOwnProperty( d ) ) {
											icdDates[d] = [];
										}
										icdDates[d].push( arrDiagnosis[y].slice( z ).replace( /\[|\]/g, '' ).toUpperCase() );
										break;
									}
								}
							}
							if ( !diagnosisDates.hasOwnProperty( d ) ) {
								diagnosisDates[d] = '';
							}
							diagnosisDates[d] = diagnosisDates[d].length > 0 ? diagnosisDates[d] + ', ' + arrDiagnosis[y] : arrDiagnosis[y];
						} // end for each segment in the diagnosis.
						// ===== Visits ===== //
						// for each chief complaint, there is a (visit date) and possibly a diagnosis (ICD10 code).
						let dates: string[] = Object.keys( chiefComplaintDates ).sort();
						for ( let y: number = 0; y < dates.length; ++y ) {
							let d = dates[y];
							const visit: InterfaceOWAPICreateDocletPayload = {
								data: {
									icd10_code: Array.isArray( icdDates[d] ) ? icdDates[d] : [],
									visit_date: dates[y],
									chief_complaint: chiefComplaintDates.hasOwnProperty( d ) ? chiefComplaintDates[d] : ''
								},
								tags: [ 'imported' ],
								title: 'Visit on ' + d + ' for ' + pvPairs[x].patient.data['mrn'],
								template_id: pvPairs[x].visit.template_id
							};
							this.mrnToVisits[mrn].push( visit );
						}
					} // end for each patient/visit pairs.
					console.log( 'patient and visits', this.mrnToVisits );
					this.mrnToProcess = Object.keys( this.mrnToVisits );
					this.createPatient();
				} else {
					console.log( 'strCSVData is not a string' );
				}
			}, (amount: number): void => {
				console.log( 'FileReader Progress', amount * 100 + '%' );
			} );
		} else {
			console.log( 'No FileList to use' );
		}
	}
}
