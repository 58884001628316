import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppEvents} from '../../app.events';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../collections/profiles';
// ===== Interfaces ===== //
import {InterfaceAppEvent, InterfaceOWUser} from '../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
//
@Component({
	selector: 'app-header',
	templateUrl: './app-header.html',
	styleUrls: [
		'./app-header.less'
	]
})
export class ComponentAppHeader implements OnDestroy {
	public routes = AppRouterLinks;
	private subUserReSync: Subscription | null = null;
	public isSignedIn = false;
	public user: InterfaceOWUser | undefined;
	//
	constructor(
		private appEvents: AppEvents,
		private auth: ServiceAuthentication,
		private colProfiles: CollectionProfiles
	) {
		this.isSignedIn = this.auth.isSignedIn();
		this.subUserReSync = this.appEvents.listen( 'user:re-sync' ).subscribe( (response: InterfaceAppEvent): void => {
			this.isSignedIn = this.auth.isSignedIn();
			this.fetchUserInfo();
		} );
		this.fetchUserInfo();
	}

	private fetchUserInfo(): void {
		if ( this.isSignedIn ) {
			this.colProfiles.getMyUserProfile( (userRecord: InterfaceOWUser | null): void => {
				if ( userRecord && this.isSignedIn ) {
					this.user = userRecord;
				}
			} );
		} else {
			this.user = undefined;
		}
	}

	public ngOnDestroy(): void {
		if ( this.subUserReSync ) {
			this.subUserReSync.unsubscribe();
			this.subUserReSync = null;
		}
	}

	public signOut(): void {
		this.appEvents.broadcast( 'user:signed-out' );
	}
}
