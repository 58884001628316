import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppConfig} from './app.config';
import {AppEvents} from './app.events';
import {AppRouterLinks} from './app.router-links';
// ===== Interfaces ===== //
import {InterfaceAppEvent, InterfaceHTTPGateway} from './interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from './services/authentication';
import {ServiceNavigate} from './services/navigate';
import {ServiceOWAPI} from './services/ow-api';
//
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [
		'./app.component.less'
	],
	encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnDestroy, OnInit {
	private subUserReSync: Subscription | null = null;
	public isSignedIn: boolean = false;
	//
	public constructor(
		private appEvents: AppEvents,
		private auth: ServiceAuthentication,
		private config: AppConfig,
		private nav: ServiceNavigate,
		private owapi: ServiceOWAPI
	) {
		this.initModalSignIn();
		this.initUserSignedIn();
		this.subUserReSync = this.appEvents.listen( 'user:re-sync' ).subscribe( (response: InterfaceAppEvent): void => {
			this.isSignedIn = this.auth.isSignedIn();
		} );
	}

	public ngOnInit(): void {
		this.auth.deserialize(); // load up any JWT and parse it's info.
		if ( this.auth.isSignedIn() ) { // if the JWT is any good... then verify with server-side.
			this.owapi.account.profile.getUserProfile().subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response && response.success && response.status === 200 ) {
					this.isSignedIn = true;
					// TODO: logic goes here to process a users profile... it must be completed before reaching the 'user:re-sync' event.
					this.runAtSignIn();
				} else {
					// BUG: if the network is offline, we just signed out the user.
					this.auth.clear();
				}
			} );
		}
	}

	// ===== User Logged-in/out ===== //
	private subUserSignedIn: Subscription | null = null;
	private subUserSignedOut: Subscription | null = null;
	private initUserSignedIn(): void {
		this.subUserSignedIn = this.appEvents.listen( 'user:signed-in' ).subscribe( (event: InterfaceAppEvent): void => {
			console.log( 'User signed in.' );
			this.runAtSignIn();
		} );
		this.subUserSignedOut = this.appEvents.listen( 'user:signed-out' ).subscribe( (event: InterfaceAppEvent): void => {
			console.log( 'User signed out.' );
			this.runAtSignOut();
		} );
	}
	private unInitUserSignedIn(): void {
		if ( this.subUserSignedIn ) {
			this.subUserSignedIn.unsubscribe();
			this.subUserSignedIn = null;
		}
		if ( this.subUserSignedOut ) {
			this.subUserSignedOut.unsubscribe();
			this.subUserSignedOut = null;
		}
	}

	// ===== Sign-In Modal ===== //
	public showSignIn: boolean = false;
	private subModalOpenSignIn: Subscription | null = null;
	private subModalCloseSignIn: Subscription | null = null;
	private initModalSignIn(): void {
		this.subModalOpenSignIn = this.appEvents.listen( 'modal:open:sign-in' ).subscribe( (event: InterfaceAppEvent): void => {
			this.showSignIn = true;
		} );
		this.subModalCloseSignIn = this.appEvents.listen( 'modal:close:sign-in' ).subscribe( (event: InterfaceAppEvent): void => {
			this.showSignIn = false;
		} );
	}
	private unInitModalSignIn(): void {
		if ( this.subModalCloseSignIn ) {
			this.subModalCloseSignIn.unsubscribe();
			this.subModalCloseSignIn = null;
		}
		if ( this.subModalOpenSignIn ) {
			this.subModalOpenSignIn.unsubscribe();
			this.subModalOpenSignIn = null;
		}
	}

	public ngOnDestroy(): void {
		this.unInitUserSignedIn();
		this.unInitModalSignIn();
		//
		if ( this.subUserReSync ) {
			this.subUserReSync.unsubscribe();
			this.subUserReSync = null;
		}
	}

	private runAtSignIn(): void {
		this.isSignedIn = true;
		// this.WebSox.start();
		this.appEvents.broadcast( 'user:re-sync' ); // tell various places to go re-fetch the user's details to update their cache's, if any.
	}

	private runAtSignOut(): void {
		this.auth.clear();
		this.isSignedIn = false;
		this.nav.toURL( AppRouterLinks.signIn );
	}
}
