<header id="masthead">
	<div class="header">
		<h1>ICD-10 Rules</h1>
		<ul class="actions nomobile">
			<li class="button">Export <i class="fa-solid fa-arrow-up"></i></li>
			<li class="button primary">Import CSV <i class="fa-solid fa-arrow-down"></i></li>
		</ul>
		<ul class="actions mobileonly">
			<li class="button"><i class="fa-solid fa-ellipsis"></i></li>
		</ul>
	</div>
	<ul class="tabs">
		<li
			[class.active]="activeTab === 'appointments'"
			(click)="activeTab = 'appointments';"
		>Appointments</li>
		<li
			[class.active]="activeTab === 'vitals'"
			(click)="activeTab = 'vitals'"
		>Vitals</li>
	</ul>
</header>
<div class="data-table">
	<ul class="entries"
		*ngIf="appointmentSettings && activeTab === 'appointments'"
	>
		<li class="row header">
			<span class="field">ICD-10 Code</span>
			<span class="field">Exact Match?</span>
			<span class="field">Next Appointment (Months)</span>
			<span class="field actions"></span>
		</li>
		<li class="row"
			*ngFor="let matchRule of appointmentSettings.settings.icd10_match; let idx = index;"
		>
			<span class="mobileonly field header">ICD-10 Code {{ matchRule.code }}
				<span class="actions"><i class="fa-solid fa-ellipsis-vertical"></i></span>
			</span>
			<span data-label="ICD-10 Code" class="field">{{ matchRule.code }}</span>
			<span data-label="Exact Match?" class="field"><input type="checkbox"
				[name]="'match-' + idx"
				[checked]="matchRule.match_type === 'full'"
				(click)="toggleAppointmentMatchType( idx );"
			/></span>
			<span data-label="Months" class="field"><input type="text"
				[tabindex]="1 + idx"
				[name]="'months-' + idx"
				(keydown)="appointmentMonthsKeyDown( $event );"
				(keyup.enter)="blur( $event );"
				(blur)="changeAppointmentMonths( idx, $event );"
				[value]="matchRule.months"
			/></span>
		</li>
	</ul>
	<ul class="entries"
		*ngIf="vitalsSettings && activeTab === 'vitals'"
	>
		<li class="row header">
			<span class="field">ICD-10 Code</span>
			<span class="field">Exact Match?</span>
			<span class="field">Blood Pressure</span>
			<span class="field">Glucometer</span>
			<span class="field">Pulse Oximetry</span>
			<span class="field">Weight</span>
			<span class="field actions"></span>
		</li>
		<li class="row"
			*ngFor="let matchRule of vitalsSettings.settings.icd10_match; let idx = index;"
		>
			<span class="mobileonly field header">ICD-10 Code {{ matchRule.code }}
				<span class="actions"><i class="fa-solid fa-ellipsis-vertical"></i></span>
			</span>
			<span data-label="ICD-10 Code" class="field">{{ matchRule.code }}</span>
			<span data-label="Exact Match?" class="field"><input type="checkbox"
				[name]="'match-' + idx"
				[checked]="matchRule.match_type === 'full'"
				(click)="toggleVitalsMatchType( idx );"
			/></span>
			<span data-label="Blood Pressure" class="field"><input type="checkbox"
				[name]="'bp-' + idx"
				[checked]="matchRule.vitals.bp"
				(click)="toggleVitalsMetric( idx, 'bp' );"
			/></span>
			<span data-label="Glucometer" class="field"><input type="checkbox"
				[name]="'sugar-' + idx"
				[checked]="matchRule.vitals.glucometer"
				(click)="toggleVitalsMetric( idx, 'glucometer' );"
			/></span>
			<span data-label="Pulse Oximetry" class="field"><input type="checkbox"
				[name]="'pulseOx-' + idx"
				[checked]="matchRule.vitals.pulse_ox"
				(click)="toggleVitalsMetric( idx, 'pulse_ox' );"
			/></span>
			<span data-label="Weight" class="field"><input type="checkbox"
				[name]="'weight-' + idx"
				[checked]="matchRule.vitals.weight"
				(click)="toggleVitalsMetric( idx, 'weight' );"
			/></span>
		</li>
	</ul>
</div>
