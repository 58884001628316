import {Component} from '@angular/core';
// ===== Components ===== //
import {ComponentCheckBoxActionLink} from '../action-linked';
// ===== Events ===== //
import {AppEvents} from '../../../../../app.events';
//
//
@Component({
	selector: 'ui-check-box-liability-waiver',
	templateUrl: '../action-linked.html',
	styleUrls: [
		'../action-linked.less'
	]
})
export class ComponentCheckBoxLiabilityWaiver extends ComponentCheckBoxActionLink {
	//
	constructor(
		private appEvents: AppEvents
	) {
		super();

		this.actions = [
			{
				text: 'I have read and agree to the'
			},
			{
				text: 'Liability Waiver',
				isLink : true
			},
			{
				text: '.',
				noNBSP: true // no space between the dot '.' and the text.
			}
		];
	}

	public override linkActivated( idx: number ): void {
		if ( idx === 1 ) { // if they clicked on actions[1], where isLink is true.
			this.appEvents.broadcast( 'modal:open:liability-waiver' );
		}
		super.linkActivated( idx );
	}
}

/*
ASSUMPTION OF RISK/WAIVER AND RELEASE OF CLAIMS/INDEMNITY AGREEMENT/SEASON PASS TERMS AND CONDITIONS
ASSUMPTION AND ACKNOWLEDGMENT OF ALL RISKS: I, ON MY OWN BEHALF, AND ON THE BEHALF OF ANY MINOR IN MY FAMILY THAT IS OBTAINING A SEASON PASS THROUGH ME, AS HIS/HER PARENT, KNOWINGLY, VOLUNTARILY, AND FREELY ACKNOWLEDGE AND ACCEPT AND ASSUME ANY AND ALL RISKS, BOTH KNOWN AND UNKNOWN, OF INJURIES OR OTHER LOSS OR DAMAGE THAT MAY BE SUFFERED WHILE ON WILD RIVERS WATERPARK PREMISES, HOWEVER CAUSED, EVEN IF CAUSED IN WHOLE OR IN PART BY THE ACTION, INACTION OR NEGLIGENCE OF ANY OF THE FOLLOWING: 1) WILD RIVERS IRVINE; LLC; 2) WILD RIVERS WATERPARK IRVINE, SPE, LLC; 3) WILD RIVERS WATERPARK IRVINE, LLC; 4) WILD RIVERS WATERPARK IRVINE HOLDINGS, LLC; 5) SURF HILL LLC; 6) SPLASH MANAGEMENT LLC; AND 7) CITY OF IRVINE (HEREINAFTER COLLECTIVELY REFERRED TO AS "RELEASED PARTIES") . SUCH RISKS INCLUDE, BUT ARE NOT LIMITED TO CUTS AND BRUISES, FALLS, BROKEN BONES, INJURIES TO WRISTS, ARMS, LEGS, FEET, ANKLES, BACKS, HEADS AND NECKS, INJURIES FROM FLIPS OR CONTACT WITH OTHER PARTICIPANTS OR OBJECTS, AND/OR SPINAL INJURIES.
RELEASE AND WAIVER OF ALL CLAIMS: I, ON MY OWN BEHALF, AND ON THE BEHALF OF ANY MINOR IN MY FAMILY THAT IS OBTAINING A SEASON PASS THROUGH ME, AS HIS/HER PARENT, EXPRESSLY WAIVE ANY AND ALL CLAIMS, SUITS OR DEMANDS FOR PERSONAL INJURY, PROPERTY DAMAGE OR OTHER LOSS AGAINST THE "RELEASED PARTIES", AND EACH OF THEM, INCLUDING BUT NOT LIMITED TO ANY AND ALL NEGLIGENCE, NEGLIGENT SUPERVISION, NEGLIGENT TRAINING, AND NEGLIGENT INSTRUCTION. TO THE FULLEST EXTENT PERMITTED BY LAW, THIS WAIVER IS INTENDED TO BE A COMPLETE RELEASE OF THE RELEASED PARTIES FOR ANY AND ALL RESPONSIBILITY FOR PERSONAL INJURIES, PROPERTY DAMAGE OR DEATH SUSTAINED BY ME OR ANY OF THE MINORS ON WILD RIVERS' PREMISES, WHETHER ARISING OUT OF OR RESULTING FROM MY OR THEIR PARTICIPATION IN ACTIVITIES, USE OF WATER SLIDES, LAZY RIVER, WAVE POOL OR OTHER WATER STRUCTURES OR EQUIPMENT, PARKING LOTS, PREMISES AND FACILITIES. THIS RELEASE APPLIES FOR ANY DATE IN THE FUTURE THAT I, OR THE MINOR(S) MAY VISIT WILD RIVERS FACILITY, AND IS FURTHER BINDING ON THE HEIRS, REPRESENTATIVES AND ESTATES OF THE UNDERSIGNED AND MINOR(S). THE SCOPE OF THIS WAIVER AND RELEASE SPECIFICALLY INCLUDES ANY CLAIMS, DAMAGES, INJURIES, EXPENSES, OR DEATH ARISING OUT OF OR RESULTING FROM ADMINISTERING OF OR FAILING TO ADMINISTER MEDICAL ASSISTANCE.
INDEMNITY: I FURTHER AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS THE RELEASED PARTIES, AND EACH OF THEM, INCLUDING ATTORNEYS' FEES, AGAINST ANY AND ALL CLAIMS, LAWSUITS OR DEMANDS FOR ANY LOSS, INJURY, DAMAGE, OR DEATH, AS WELL AS PROPERTY DAMAGE, ARISING OUT OF, CONNECTED TO, OR RELATING IN ANY WAY TO THE PARTICIPATION IN THE ACTIVITIES DESCRIBED HEREIN, AND/OR THE PRESENCE ON THE PROPERTY, INCLUDING BUT NOT LIMITED TO ANY LAWSUIT FILED ON BEHALF OF ANY MINOR(S).
RECREATIONAL SERVICES: I FURTHER ACKNOWLEDGE THAT RECREATIONAL SERVICES ARE BEING PROVIDED BY THE RELEASED PARTIES, AND NO SALE, LEASE OR BAILMENT OF EQUIPMENT IS ANY PART OF TRANSACTION HEREIN. AS SUCH, I AGREE THAT NO CLAIM OF STRICT PRODUCTS LIABILITY WILL BE OR CAN BE ASSERTED AGAINST RELEASED PARTIES.
POSTED SIGNS AND SAFETY RULES: I, ON MY OWN BEHALF, AND ON THE BEHALF OF ANY MINOR IN MY FAMILY THAT IS OBTAINING A SEASON PASS THROUGH ME, AS HIS/HER PARENT, AGREE TO READ, OBEY, AND COMPLY WITH ALL POSTED, SPOKEN AND/OR WRITTEN SAFETY RULES WHILE ATTENDING WILD RIVERS, PARTICIPATING IN ACTIVITIES, AND USING WATERSLIDES, LAZY RIVER, WAVE POOL AND OTHER WATER STRUCTURES AND EQUIPMENT, AND UNDERSTAND THAT THE FAILURE TO DO SO MAY RESULT IN SERIOUS INJURY OR DEATH.
*/
