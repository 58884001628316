export const AppRouterLinks = {
	// in the app-routing.module.ts, the route paths MUST NOT start with a /slash.
	// but when using it on <a [routerLink]> ...it needs to start with a /slash.
	//
	// for <a [routerLink]> -- the reasoning is because if you're on "/somePath/here"
	// ...and you use <a [routerLink]="there">
	// ...you will travel to "/somePath/there"
	//
	//
	// (nickname) : (path/to/somewhere)
	'appointments': 'appointments',
	'database': 'database',
	'getHelp': 'get-help',
	'messages': 'messages',
	'patients': 'patients',
	'settings': 'settings',
	'signIn': 'signin',
	'snapshot': 'snapshot',
	'visits': 'visits'
};
