import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../../app.config';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway} from '../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../authentication';
import {ServiceOWGateway} from './ow-gateway';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWAPISupport {
	private routePrefix = 'support/';
	//
	constructor(
		private auth: ServiceAuthentication,
		private config: AppConfig,
		private gateway: ServiceOWGateway
	) {
		//
	}

	// ========================= Support Tickets ========================= //

	public submitSupport( // 7 fields
		firstName: string, lastName: string,
		email: string, phone: string,
		description: string, byEmail: boolean, byPhone: boolean
	): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix, JSON.stringify( {
			issue_title: this.config.getAppShortName(),
			issue_description: description,
			issuer_name: firstName + ' ' + lastName,
			issuer_phone: phone,
			issuer_email: email,
			issuer_pref_contact: byEmail && byPhone ? 'Email/Phone' : ((byEmail ? 'Email' : '') + (byPhone ? 'Phone' : ''))
		} ), true );
	}
}
