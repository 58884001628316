import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../../../app.config';
// ===== Interfaces ===== //
import {InterfaceAnyObject, InterfaceHTTPGateway, InterfaceOWAPIOrderItems, InterfaceOWAPIOrderPaymentInfo} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWAPIWorkspaceActions {
	private routePrefix = 'workspace/';
	//
	constructor(
		private auth: ServiceAuthentication,
		private config: AppConfig,
		private gateway: ServiceOWGateway
	) {
		//
	}

	public getUserPublicInfo( profileIDs: string[] ): Observable<InterfaceHTTPGateway> {
		if ( profileIDs.length > 0 ) {
			return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/action/5f518939e7783ee90d2cb36e', JSON.stringify( {
				profile_ids: profileIDs // if you don't send this in, it grabs all users.
			} ) );
		}
		return this.gateway.requestDenied();
	}

	public recordResourceUse( resource: string, type: string, context: InterfaceAnyObject ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/action/62aef2c2e32a10777944bee8', JSON.stringify( {
			context: context,
			realm_id: this.config.getRealmID(),
			resource: resource,
			type: type
		} ), true );
	}
}
