<header id="masthead">
	<div class="header">
		<h1>Patient Records</h1>
		<ul class="actions nomobile">
			<li class="button">Export <i class="fa-solid fa-arrow-up"></i></li>
			<li class="button primary">Import CSV <i class="fa-solid fa-arrow-down"></i></li>
		</ul>
		<ul class="actions mobileonly">
			<li class="button"><i class="fa-solid fa-ellipsis"></i></li>
		</ul>
	</div>
	<ul class="tabs">
		<li class="active">All Patients</li>
		<li>Inactive Patients</li>
	</ul>
</header>
<div class="data-table">
	<ul class="entries">
		<li class="row header">
			<!--            This would be a select-all. selected icon is <i class="fa-regular fa-square-check"></i>-->
			<span class="field select centered"><i class="fa-regular fa-square"></i></span>
			<span class="field">MRN</span>
			<span class="field">Last</span>
			<span class="field">First</span>
			<span class="field">Birthdate</span>
			<span class="field">Gender</span>
			<span class="field">Phone</span>
			<span class="field actions"></span>
		</li>
		<li class="row" *ngFor="let p of patients; let idx = index; trackBy: trackByMRN">
			<span class="mobileonly field header">Patient {{ p.data['mrn'] }}<span class="actions"><i
					class="fa-solid fa-ellipsis-vertical"></i></span></span>
			<span class="field select centered"><i class="fa-regular fa-square"></i></span>
			<span data-label="MRN" class="field">{{ p.data['mrn'] }}</span>
			<span data-label="Last Name" class="field">{{ p.data['last_name'] | lowercase }}</span>
			<span data-label="First Name" class="field">{{ p.data['first_name'] | lowercase }}</span>
			<span data-label="Birthdate" class="field">{{ p.data['dob'] | lowercase }}</span>
			<span data-label="Gender" class="field">{{ p.data['gender'] | lowercase }}</span>
			<span data-label="Phone"
			      class="field">{{ (p.data['cell_phone'] || p.data['home_phone'] || p.data['work_phone']) | lowercase }}</span>
			<span data-label="Something" class="field actions"><i class="fa-solid fa-ellipsis-vertical"></i></span>
		</li>
	</ul>
</div>
