import {Component, EventEmitter, Input, Output} from '@angular/core';
// ===== Components ===== //
import {ComponentInput} from '../input';
// ===== Services ===== //
import {ServiceRegex} from "../../../../services/regex";
//
@Component({
	selector: 'labeled-input',
	templateUrl: './labeled-input.html',
	styleUrls: [
		'./labeled-input.less'
	]
})
export class ComponentLabeledInput extends ComponentInput {
	@Input() label = '';
	//
	constructor(
		regX: ServiceRegex // seems if you make it private/public/protected, it will create another instance, rather than pass along an injectable.
	) {
		super( regX ); // this child class "is a" parent class due to inheritance, so it requires the same dependencies.
	}
}
