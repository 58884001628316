import {Component, EventEmitter, Input, Output} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceNavMenuItem} from '../../../interfaces/interfaces';
//
@Component({
	selector: 'nav-vertical',
	templateUrl: './nav-vertical.html',
	styleUrls: [
		'./nav-vertical.less'
	]
})
export class ComponentNavVertical {
	@Input() activeIndex = 0;
	@Output() selected: EventEmitter<number> = new EventEmitter<number>();
	@Input() entries: InterfaceNavMenuItem[] = [];
	//
	constructor() {
		//
	}

	public itemSelected( idx: number ): void {
		this.selected.emit( idx );
	}
}
