import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../../../app.config';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWAPIWorkspaceSettings {
	private routePrefix = 'workspace/';
	//
	constructor(
		private appConfig: AppConfig,
		private gateway: ServiceOWGateway
	) {
		//
	}

	public getSettings(): Observable<InterfaceHTTPGateway> { // apiResponse.data.items[]
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( this.appConfig.getWorkspaceID() ) + '/settings' );
	} // groups of settings are a thing. this gets them all.

	public getSettingsByID( id: string ): Observable<InterfaceHTTPGateway> { // apiResponse.data === settings
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( this.appConfig.getWorkspaceID() ) + '/settings/' + id );
	} // just get 1 group of settings. because maybe you want to put a bunch of flags and settings together, but not everything into one fat document.

	// TODO: remove a setting

	// TODO: update a setting

	// TODO: create a setting
}
