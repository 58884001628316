import {Component} from '@angular/core';
// ===== Components ===== //
import {ComponentCheckBoxActionLink} from '../action-linked';
// ===== Events ===== //
import {AppEvents} from '../../../../../app.events';
//
@Component({
	selector: 'ui-check-box-privacy-policy',
	templateUrl: '../action-linked.html',
	styleUrls: [
		'../action-linked.less'
	]
})
export class ComponentCheckBoxPrivacyPolicy extends ComponentCheckBoxActionLink {
	//
	constructor(
		private appEvents: AppEvents
	) {
		super();
		this.actions = [
			{
				text: 'I have read and agree to the'
			},
			{
				text: 'Privacy Policy',
				isLink: true
			},
			{
				text: '.',
				noNBSP: true
			}
		];
	}

	public override linkActivated( idx: number ): void {
		if ( idx === 1 ) {
			this.appEvents.broadcast( 'modal:open:privacy-policy' );
		}
		super.linkActivated( idx );
	}
}
