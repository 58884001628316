import {Injectable} from '@angular/core';
//
@Injectable({
	providedIn: 'root'
})
export class BaseConfig { // --- DEV --- baseAPI and ioHost should be different than live.
	protected baseAPI = 'https://api.omniplatform.dev/'; // live: .com and staging: .dev
	protected realmID = '62e8ca6796e60e28d1fb7a32'; // MediText
	protected workspaceID = '62e8cae296e60e28d1fb7a33'; // MediText
	protected ioHost = ''; // https://service.omniplatform.io:8200'; // don't add the trailing slash.
	// live's ioHost's port number ought to be in the 9K's. testing ought to be in the 8K's.  8200, 9200, etc.
	protected appShortName = 'MT'; // used for support tickets. needs the short-code (acronym) of the project name.
	//
	protected templateIDs: {
		[key: string]: string;
	} = {
		'Account': '62fc07948a2a570900d74b23',
		'ICD10 Code': '63057c5610b9e26e9494e708', // "ICD10 Code 2022"
		'Metrics': '631230a17a9640d2b2db1333',
		'Patient': '62ec0e381c4d4e1bbe8663eb',
		'Patient Visit': '63191d7eb73dba795fe2e405',
		'Patient Appointment': '6336040db954a353935f8b0c',
		'Provider': '632c9376ce8860da1493e55a'
	};
	// ===== Weaves ===== //
	// (Patient)-(Metrics)
	// (Patient)-(Patient Visit)
	// (Patient)-(Patient Appointment)
	// ========== //
	protected roleIDs: {
		[key: string]: string;
	} = {
		// 'Name': 'id'
	};
	// ===== Settings ===== //
	protected settingsIDs: {
		[key: string]: string;
	} = {
		// 'nickname' : (doclet).setting_id // ObjectId // don't use the ._id key's value in the API, it doesn't return anything.
		'ICD10Vitals': '6335c65131f49d219e605c79', // db.settings.findOne( { "_id" : ObjectId( "6335c88c31f49d219e605c7a" ) } );
		'ICD10AppointmentMonths': '6335d65831f49d219e605c7b'//'6335d6ef31f49d219e605c7c'
	};
	// ===== //
	constructor() {
		//
	}
}
