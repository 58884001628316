// ===== APP ===== //
import {AppRouterLinks} from './app.router-links';
// ===== Collections ===== //
//
// ===== Components ===== //
//
// ===== Modals ===== //
//
// ===== Modules ===== //
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// ===== Pages ===== //
import {PageAppointment} from './pages/appointment/appointment';
import {PageAppointments} from './pages/appointments/appointments';
import {PageDatabase} from './pages/database/database';
import {PageDataImport} from './pages/data-import/data-import'; // temp code
import {PageGetHelp} from './pages/get-help/get-help';
import {PageMessages} from './pages/messages/messages';
import {PagePatient} from './pages/patient/patient';
import {PagePatients} from './pages/patients/patients';
import {PageSettings} from './pages/settings/settings';
import {PageSignIn} from './pages/sign-in/sign-in';
import {PageSnapshot} from './pages/snapshot/snapshot';
import {PageVisit} from './pages/visit/visit';
import {PageVisits} from './pages/visits/visits';
// ===== Services ===== //
//
// ===== Transformers ===== //
//
const routes: Routes = [
	// pathMatch => 'full' vs 'prefix'
	// 'prefix' is used when you have children items that match the rest. unless it ultimately doesn't match the entire URL, then it never actually triggers a match and keeps looking.
	// 'full' is used when there aren't any children, like at the current layer, at the end, or just the front.
	// there isn't a partial-match option. only a "full" or "some here, the rest there".
	{
		component: PageDataImport,
		path: 'temp-code',
		pathMatch: 'full'
	},
	{
		children: [
			{
				component: PageAppointment,
				path: ':id',
				pathMatch: 'full'
			}
		],
		component: PageAppointments,
		path: AppRouterLinks.appointments,
		pathMatch: 'prefix'
	},
	{
		component: PageDatabase,
		path: AppRouterLinks.database,
		pathMatch: 'full'
	},
	{
		component: PageGetHelp,
		path: AppRouterLinks.getHelp,
		pathMatch: 'full'
	},
	{
		component: PageMessages,
		path: AppRouterLinks.messages,
		pathMatch: 'full'
	},
	{
		children: [
			{
				component: PagePatient, // patient details
				path: ':id',
				pathMatch: 'full'
			}
		],
		component: PagePatients, // patient listing
		path: AppRouterLinks.patients,
		pathMatch: 'prefix'
	},
	{
		component: PageSettings,
		path: AppRouterLinks.settings,
		pathMatch: 'full'
	},
	{
		component: PageSignIn,
		path: AppRouterLinks.signIn,
		pathMatch: 'full'
	},
	{
		path: 'login',
		pathMatch: 'full',
		redirectTo: AppRouterLinks.signIn
	},
	{
		path: 'log-in',
		pathMatch: 'full',
		redirectTo: AppRouterLinks.signIn
	},
	{
		path: 'sign-in',
		pathMatch: 'full',
		redirectTo: AppRouterLinks.signIn
	},
	{
		component: PageSnapshot,
		path: AppRouterLinks.snapshot,
		pathMatch: 'full'
	},
	{
		children: [
			{
				component: PageVisit, // visit details
				path: ':id',
				pathMatch: 'full'
			}
		],
		component: PageVisits, // visit listing
		path: AppRouterLinks.visits,
		pathMatch: 'prefix'
	},
	{
		path: '**',
		redirectTo: AppRouterLinks.snapshot
	}
];
//
@NgModule({
	declarations: [ // components, modals, pages.
	],
	exports: [ // modules
		RouterModule
	],
	imports: [ // modules
		RouterModule.forRoot( routes )
	],
	providers: [ // services
	]
})
export class AppRoutingModule {}
