// ===== App ===== //
import {AppComponent} from './app.component';
import {AppConfig} from './app.config';
import {AppEvents} from './app.events';
import {AppRoutingModule} from './app-routing.module';
// ===== Collections ===== //
import {Collection} from './collections/collection';
import {CollectionDoclets} from './collections/doclets';
import {CollectionProfiles} from './collections/profiles';
import {CollectionTemplates} from './collections/templates';
// ===== Components ===== //
//
// ===== Modals ===== //
import {ModalBackDrop} from './modals/back-drop/back-drop';
import {ModalSignIn} from './modals/sign-in/sign-in';
// ===== Modules ===== //
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {ModuleComponents} from './components/components.module';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
// ===== Pages ===== //
import {PageAppointment} from './pages/appointment/appointment';
import {PageAppointments} from './pages/appointments/appointments';
import {PageDatabase} from './pages/database/database';
import {PageDataImport} from './pages/data-import/data-import';
import {PageGetHelp} from './pages/get-help/get-help';
import {PageMessages} from './pages/messages/messages';
import {PagePatients} from './pages/patients/patients';
import {PagePatient} from './pages/patient/patient';
import {PageSettings} from './pages/settings/settings';
import {PageSignIn} from './pages/sign-in/sign-in';
import {PageSnapshot} from './pages/snapshot/snapshot';
import {PageVisit} from './pages/visit/visit';
import {PageVisits} from './pages/visits/visits';
// ===== Services ===== //
import {ServiceAuthentication} from './services/authentication';
import {ServiceFileReader} from './services/file-reader';
import {ServiceNavigate} from './services/navigate';
import {ServiceNotifications} from './services/notifications';
import {ServiceOWAPI} from './services/ow-api';
import {ServiceOWAPIAccount} from './services/api/ow/account';
import {ServiceOWAPIAccountAuth} from './services/api/ow/accounts/auth';
import {ServiceOWAPIAccountProfile} from './services/api/ow/accounts/profile';
import {ServiceOWAPIAccountRegistration} from './services/api/ow/accounts/registration';
import {ServiceOWAPIAssets} from './services/api/ow/assets';
import {ServiceOWAPIAssetsFiles} from './services/api/ow/assets/files';
import {ServiceOWAPIMessage} from './services/api/ow/message';
import {ServiceOWAPIMessageChannels} from './services/api/ow/messages/channels';
import {ServiceOWAPISupport} from './services/api/ow/support';
import {ServiceOWAPIWorkspace} from './services/api/ow/workspace';
import {ServiceOWAPIWorkspaceActions} from './services/api/ow/workspace/actions';
import {ServiceOWAPIWorkspaceDoclets} from './services/api/ow/workspace/doclets';
import {ServiceOWAPIWorkspaceSettings} from './services/api/ow/workspace/settings';
import {ServiceOWAPIWorkspaceTasks} from './services/api/ow/workspace/tasks';
import {ServiceOWAPIWorkspaceTemplates} from './services/api/ow/workspace/templates';
import {ServiceOWGateway} from './services/api/ow/ow-gateway';
import {ServiceRegex} from './services/regex';
import {ServiceSorting} from './services/sorting';
import {ServiceWorkspaces} from './services/workspaces';
// ===== Transformers ===== //
import {TransformerCSV} from './transformers/csv';
import {TransformerMediTextDataImporter} from './transformers/meditext/data-importer';
import {TransformerMongoObjectId} from './transformers/mongo/ObjectId';
//
@NgModule({
	bootstrap: [
		AppComponent
	],
	declarations: [ // modals (not modules), pages, components
		AppComponent,
		ModalBackDrop,
		ModalSignIn,
		PageAppointment,
		PageAppointments,
		PageDatabase,
		PageDataImport,
		PageGetHelp,
		PageMessages,
		PagePatient,
		PagePatients,
		PageSettings,
		PageSignIn,
		PageSnapshot,
		PageVisit,
		PageVisits
	],
	exports: [ // modules
	],
	imports: [ // modules (not modals)
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		ModuleComponents,
		FormsModule,
		HttpClientModule
	],
	providers: [ // collections, services, transformers, injectables
		AppConfig,
		AppEvents,
		Collection,
		CollectionDoclets,
		CollectionProfiles,
		CollectionTemplates,
		ServiceAuthentication,
		ServiceFileReader,
		ServiceNavigate,
		ServiceNotifications,
		ServiceOWAPI,
		ServiceOWAPIAccount,
		ServiceOWAPIAccountAuth,
		ServiceOWAPIAccountProfile,
		ServiceOWAPIAccountRegistration,
		ServiceOWAPIAssets,
		ServiceOWAPIAssetsFiles,
		ServiceOWAPIMessage,
		ServiceOWAPIMessageChannels,
		ServiceOWAPISupport,
		ServiceOWAPIWorkspace,
		ServiceOWAPIWorkspaceActions,
		ServiceOWAPIWorkspaceDoclets,
		ServiceOWAPIWorkspaceSettings,
		ServiceOWAPIWorkspaceTasks,
		ServiceOWAPIWorkspaceTemplates,
		ServiceOWGateway,
		ServiceRegex,
		ServiceSorting,
		ServiceWorkspaces,
		TransformerCSV,
		TransformerMediTextDataImporter,
		TransformerMongoObjectId
	]
})
export class AppModule {}
