import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
//
@Component({
	selector: 'ui-drop-down',
	templateUrl: './drop-down.html',
	styleUrls: [
		'./drop-down.less'
	]
})
export class ComponentDropDown implements OnInit {
	@Input() items: string[] = [];
	@Input() placeholderText: string | undefined = '';
	@Input() selectedValue = '';
	@Input() isRequired = false;
	@Input() isError = false;
	@Output() changed: EventEmitter<string | null> = new EventEmitter<string | null>();
	//
	public selectedIndex: number | null = null;
	public selectingAnItem = false;
	//
	constructor() {
		//
	}

	public _onInit(): void {
		if ( this.selectedValue.length > 0 ) {
			for ( let x = 0; x < this.items.length; ++x ) {
				if ( this.items[x] === this.selectedValue ) {
					this.selectedIndex = x;
					break;
				}
			}
		}
		if ( this.selectedIndex === null && this.isRequired ) {
			this.isError = true;
		}
	}

	public ngOnInit(): void {
		if ( typeof this.placeholderText !== 'string' || this.placeholderText.length < 1 ) {
			this.placeholderText = 'Please select an item';
		}
		this._onInit();
	}

	public toggleMenu( show?: boolean ): void {
		if ( typeof show === 'boolean' ) { // undefined, null, whatever.
			this.selectingAnItem = show;
		} else {
			this.selectingAnItem = !this.selectingAnItem;
		}
	}

	public setSelected( idx: number ): void {
		this.selectedIndex = idx >= 0 && idx < this.items.length ? idx : null;
		if ( this.selectedIndex === null && this.isRequired ) {
			this.isError = true;
		}
		this.selectingAnItem = false;
		this.changed.emit( this.selectedIndex === null ? null : this.items[ this.selectedIndex ] );
	}
}
