import {Injectable} from '@angular/core';
// ===== Services ===== //
import {ServiceOWAPIAssetsFiles} from './assets/files';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWAPIAssets {
	private routePrefix = 'assets/';
	//
	constructor(
		public files: ServiceOWAPIAssetsFiles
	) {
		//
	}
}
