import {Injectable} from '@angular/core';
// ===== Services ===== //
import {ServiceOWAPIAccount} from './api/ow/account';
import {ServiceOWAPIAssets} from './api/ow/assets';
import {ServiceOWAPIMessage} from './api/ow/message';
import {ServiceOWAPISupport} from './api/ow/support';
import {ServiceOWAPIWorkspace} from './api/ow/workspace';
//
@Injectable({
	providedIn : 'root'
})
export class ServiceOWAPI {
	//
	constructor(
		public account: ServiceOWAPIAccount, // sign-in, sign-up, forgot password, verify email address, profile, update password
		public assets: ServiceOWAPIAssets, // files
		public message: ServiceOWAPIMessage, // chat
		public support: ServiceOWAPISupport, // support tickets
		public workspace: ServiceOWAPIWorkspace // doclets, workflow tasks, custom actions
	) {
		//
	}
}
