<span class="check-box-wrapper {{ cssSize ? 'size-' + cssSize : '' }}">
	<span [ngClass]="{
		'check-box' : true,
		'checked' : isChecked,
		'error' : !!error
	}" (click)="toggle();">
		<img alt="" src="../../../../assets/img/ui-icon/check.png" />
	</span>
	<span (click)="toggle();" class="check-box-display">{{ text }}</span>
</span>
