import {Injectable} from '@angular/core';
// ===== Services ===== //
import {ServiceOWAPIMessageChannels} from './messages/channels';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWAPIMessage {
	private routePrefix = 'message/';
	//
	constructor(
		public channels: ServiceOWAPIMessageChannels
	) {
		//
	}
}
