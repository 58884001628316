import {Component} from '@angular/core';
// ===== App ===== //
import {AppEvents} from '../../app.events';
// TODO: app-router-links
// ===== Collections ===== //
import {CollectionProfiles} from '../../collections/profiles';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway, InterfaceOWAPISignInResponse, InterfaceOWUserAuth} from '../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceNavigate} from '../../services/navigate';
import {ServiceOWAPI} from '../../services/ow-api';
import {ServiceRegex} from '../../services/regex';
//
@Component({
	selector: 'page-sign-in',
	templateUrl: './sign-in.html',
	styleUrls: [
		'./sign-in.less'
	]
})
export class PageSignIn { // TODO: app-router-links.ts and use variables for routes instead of strings.
	public email: string = '';
	public password: string = '';
	public badEmail: boolean = false;
	public badLogIn: boolean = false;
	public busy: boolean = false;
	//
	constructor(
		private appEvents: AppEvents,
		private auth: ServiceAuthentication,
		private colProfiles: CollectionProfiles,
		private nav: ServiceNavigate,
		private owapi: ServiceOWAPI,
		private regX: ServiceRegex
	) {
		//
	}

	public checkEmail(): void {
		this.badEmail = !this.regX.emailRegExp.test( this.email );
	}

	public signIn(): void {
		if ( !this.busy ) {
			this.badLogIn = false;
			if ( this.regX.emailRegExp.test( this.email ) && this.password.length > 0 ) {
				this.busy = true;
				this.owapi.account.auth.login( this.email, this.password ).subscribe( (response : InterfaceHTTPGateway): void => {
					this.busy = false;
					if ( response && response.success && response.status === 200 ) {
						const logInResult: InterfaceOWAPISignInResponse = response.data;
						if ( logInResult && logInResult.data ) {
							const authData: InterfaceOWUserAuth = logInResult.data;
							if ( authData && authData.auth_key && authData.user && authData.user._id && authData.user._id.$oid ) {
								this.auth.setTokens( authData.auth_key, authData.user._id.$oid );
								this.colProfiles.cacheUserProfiles( [ authData.user ] );
							}
						}
						if ( this.auth.isSignedIn() ) {
							this.appEvents.broadcast( 'user:signed-in' );
							this.nav.toURL( '' ); // TODO: app-router-links to the dashboard.
						} else {
							this.badLogIn = true;
						}
					} else {
						this.badLogIn = true;
					}
				} );
			} else {
				this.badLogIn = true;
			}
		}
	}

	public blur( E: Event ): void { // typescript error: $event.target.blur is not a function
		(E.target as HTMLElement).blur(); // because $event.target is an unknown type... typescript fail.
	}
}
