import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../../../app.config';
// ===== Interfaces ===== //
import {InterfaceAnyObject, InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWAPIWorkspaceDoclets {
	private routePrefix = 'workspace/';
	//
	constructor(
		private auth: ServiceAuthentication,
		private config: AppConfig,
		private gateway: ServiceOWGateway
	) {
		//
	}

	// ========================= Doclets, Weaves ========================= //

	public createDoclet( title: string, data: InterfaceAnyObject, template_id: string, tags?: string[] ): Observable<InterfaceHTTPGateway> {
		// HTTP status of 200 on success.
		// no payload after creating the object. no object_id. you won't know how to fetch it yet...
		// { "success" : true, "status" : 200, "data" : { "status" : 200, "results" : [ { "items" : [] } ] } }
		return this.gateway.fetch( 'put', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/doclet', JSON.stringify( {
			data: data,
			tags: Array.isArray( tags ) ? tags : [],
			template_id: template_id,
			title: title
		} ) );
		/* InterfaceHTTPGateway.data =
		{
			"status": 200,
			"data": {
				"doclet_id": "628d580042ed2615904f26a5"
			},
			"messages": {
			"info": [],
				"warnings": [],
				"errors": []
			},
			"meta": {
				"time_processed": "2022-May-24 17:11:12"
			},
			"ts": "2022-May-24 22:11:12"
		}
		*/
	}

	public getDocletByID( docletId: string | string[] ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/doclet/' + encodeURIComponent( Array.isArray( docletId ) ? docletId.join( ',' ) : docletId ) );
	}

	public getDocletsByTemplateID( templateID: string | string[], query?: string, tempNoAuth?: boolean ): Observable<InterfaceHTTPGateway> {
		// TODO: remove the tempNoAuth because that's a quick-fix to needing things without auth....
		let qry = 'template_id:';
		if ( Array.isArray( templateID ) ) {
			qry += '[{id}' + templateID.join( ',{id}' ) + ']';
		} else {
			qry += '{id}' + templateID;
		}
		if ( query && query.length > 0 ) {
			qry += '|' + query; // 'data.status:active'
		}
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/doclets/?query=' + encodeURIComponent( qry ), undefined, tempNoAuth );
	}

	public getWeavesByDocletID( docletId: string | string[] ): Observable<InterfaceHTTPGateway> {
		// get a list of entries that are woven to a doclet.
		// this doesn't get the doclets woven to a doclet. it gets the meta-info, called weaves.
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/doclet/' + encodeURIComponent( Array.isArray( docletId ) ? docletId.join( ',' ) : docletId ) + '/weaves' );
	}

	private getWovenDocletsFromDocletID_filterByTemplate( docletId: string, template: string ): Observable<InterfaceHTTPGateway> {
		// grab the records that are woven to a doclet, but only ones that match a template name.
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/doclet/' + encodeURIComponent( docletId ) + '/weaves/doclets?query=' + encodeURIComponent( 'template:' + template ) );
	}

	public getWovenDocletsFromDocletID( docletId: string ): Observable<InterfaceHTTPGateway> {
		// grab the records that are woven to a doclet.
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/doclet/' + encodeURIComponent( docletId ) + '/weaves/doclets' );
	}

	public updateDocletData( docletId: string, data: InterfaceAnyObject ): Observable<InterfaceHTTPGateway> {
		// data just needs to be { "name of component" : "value", "other name" : "value" }
		// the names come from the template.
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/doclet/' + encodeURIComponent( docletId ), JSON.stringify( {
			data: data
		} ) );
	}

	public addDocletTags( docletId: string, tags: string[] ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/doclet/' + encodeURIComponent( docletId ), JSON.stringify( {
			tags: tags // TODO: remove from tags, overwrite tags, add tags, etc.
		} ) );
	}

	public updateDocletTitle( docletId: string, title: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/doclet/' + encodeURIComponent( docletId ), JSON.stringify( {
			title: title
		} ) );
	}

	public createWeave( docletId: string, data: InterfaceAnyObject, otherDocletId: string ): Observable<InterfaceHTTPGateway> {
		// this won't create schema, this will only populate it.
		// for now, you have to manually make the schema in db.weaves_config
		// db.weaves_config.findOne()
		/*
		{
			"_id" : ObjectId("6279ced7e32a107779b47de4"),
			"weave" : [
				{
					"tid" : ObjectId("627146b1e32a107779d0d88b"),
					"ext" : [ ],
					"name" : "Family"
				},
				{
					"tid" : ObjectId("627959e6f99ef9cce0fba2b3"),
					"name" : "Consumer",
					"ext" : [
						{
							"type" : "text",
							"key" : "role",
							"label" : "Role"
						}
					]
				}
			]
		}
		*/
		const payload = {
			'data' : [ // an array of objects. can have multiple objects.
				{
					doclet_id: otherDocletId,
					data: data // data = { 'role': 'Primary' }
				}
			]
		};
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/doclet/' + encodeURIComponent( docletId ) + '/weaves', JSON.stringify( payload ) );
		// returns 200 on success. see InterfaceOWAPICreateWeaveResponse.
	}
}
