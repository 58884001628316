import {Component, EventEmitter, Input, Output} from '@angular/core';
//
@Component({
	selector: 'ui-check-box',
	templateUrl: './check-box.html',
	styleUrls: [
		'./check-box.less'
	]
})
export class ComponentCheckBox {
	public isChecked = false;
	@Input() text = '';
	@Input() error = false;
	@Input() checked = false;
	@Input() cssSize: number | undefined = undefined;
	@Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();
	//
	constructor() {
		//
	}

	public toggle(): void {
		this.error = false;
		this.isChecked = !this.isChecked;
		this.changed.emit( this.isChecked );
	}
}
