import {EventEmitter, Injectable, Output} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceAnyObject} from '../interfaces/interfaces';
// ===== Types ===== //
import {typeProcessFetchedRecord, typeRequestForRecord} from './types/flow-control';
@Injectable({
	providedIn: 'root',
})
export class Collection { // TODO: worry about the cache being out of date because a record was deleted from the DB, but the collection doesn't know...
	@Output() updated: EventEmitter<void> = new EventEmitter<void>(); // use: this.updated.emit(); when the collection was updated.
	protected records: InterfaceAnyObject = {};
	protected pendingRequestsByID: {
		// <key> : [ callback1, callback2, callback3 ] // callback = function( (record) => { console.log( record ); } );
		[key: string]: (
			(record: any | null) => any // fn signature
		)[]; // an array of functions.
	} = {};
	protected pendingBulkRecordRequest: { // bulk record request, with a filter. once filtered, the callback (cb) is used to deliver the filtered records.
		[key: string]: {
			cb: (records: any[]) => any,
			filter: false | ((record: any) => boolean) // no filter (false) or a predicate function
		}[]
	} = {};
	protected counter = 0;
	protected stackCookies: {
		[key: string]: string | null;
	} = {};

	constructor() {
		// ===== //
		// at some point, this collection will be listening for real-time updates.
		// it will then process new records on it's own, and issue out a this.updated.emit() to tell other places things have been updated.
		// ===== //
		// so you should use the getCachedWhatever() methods when these updates happen,
		// and only use the fetchWhatever() methods to grab the initial data-set.
		// ===== //
	}

	public clear(): void {
		this.records = {};
		this.stackCookies = {};
		let keys = Object.keys( this.pendingRequestsByID );
		for ( let x = 0; x < keys.length; ++x ) {
			this.resolvePendingRequest( keys[x] );
		}
		keys = Object.keys( this.pendingBulkRecordRequest );
		for ( let x = 0; x < keys.length; ++x ) {
			this.resolvePendingFilteredRequest( keys[x] );
		}
		this.updated.emit();
	}

	protected stubFn(): any {
		// a do-nothing placeholder method. used to make other logic behave as intended.
	}

	protected createStackCookie(): string { // an elaborate way to make a unique id.
		this.counter = (1 + this.counter) % 0xFFFF;
		return Math.random().toString() + '_' + new Date().getTime().toString() + '_' + this.counter;
	}

	public cacheRecords( data: any[] ): void {
		for ( let x = 0; x < data.length; ++x ) {
			if ( data[x] && data[x].hasOwnProperty( '_id' ) ) {
				if ( typeof data[x]._id.$oid === 'string' ) {
					this.records[ data[x]._id.$oid ] = data[x];
				} else if ( typeof data[x]._id === 'string' ) {
					this.records[ data[x]._id ] = data[x];
				}
			}
		}
		this.updated.emit();
	}

	public getCachedRecordByID( id: string ): any | null {
		if ( this.records.hasOwnProperty( id ) && this.records[id] ) {
			return this.records[id];
		}
		return null;
	}

	public getCachedRecords(): any[] {
		const keys = Object.keys( this.records );
		const output = [];
		for ( let x = 0; x < keys.length; ++x ) {
			output.push( this.records[ keys[x] ] );
		}
		return output;
	}

	protected resolvePendingRequest( requestID: string ): void {
		if ( Array.isArray( this.pendingRequestsByID[requestID] ) ) {
			for ( let x = 0; x < this.pendingRequestsByID[requestID].length; ++x ) {
				if ( typeof this.pendingRequestsByID[requestID][x] === 'function' ) {
					this.pendingRequestsByID[requestID][x]( this.getCachedRecordByID( requestID ) );
				}
			}
			this.pendingRequestsByID[requestID] = [];
			delete this.pendingRequestsByID[requestID];
		}
	}

	protected resolvePendingFilteredRequest( requestID: string ): void {
		if ( Array.isArray( this.pendingBulkRecordRequest[requestID] ) ) {
			for ( let x = 0; x < this.pendingBulkRecordRequest[requestID].length; ++x ) {
				if ( this.pendingBulkRecordRequest[requestID][x] && typeof this.pendingBulkRecordRequest[requestID][x].cb === 'function' ) {
					if ( typeof this.pendingBulkRecordRequest[requestID][x].filter === 'function' ) {
						const F: CallableFunction = this.pendingBulkRecordRequest[requestID][x].filter as CallableFunction; // typescript thinks this may be the 'false' value. overriding it by doing "blah as blah".
						const filteredOutput: any[] = [];
						const keys = Object.keys( this.records );
						try {
							for ( let y = 0; y < keys.length; ++y ) {
								if ( F( this.records[ keys[y] ] ) ) {
									filteredOutput.push( this.records[ keys[y] ] );
								}
							}
						} catch ( fail ) {
							console.log( 'Could not filter the output of records using the predicate function', this.pendingBulkRecordRequest[requestID][x].filter, fail );
						}
						this.pendingBulkRecordRequest[requestID][x].cb( filteredOutput );
					} else {
						// no filter. all records are returned.
						this.pendingBulkRecordRequest[requestID][x].cb( this.getCachedRecords() );
					} // else the callback's filter was not a function
				} // end if the callback really is a function
			} // end for each bulk record callback
			this.pendingBulkRecordRequest[requestID] = [];
			delete this.pendingBulkRecordRequest[requestID];
		}
	}

	// protected fetchRecordByID( id: string, callbackAcquireRecord: (callbackAfterRecordRequest: (callbackAfterAcquiringRecord: (callbackAfterCachingRecord: (suppressUpdate?: true) => void) => void) => void) => void, callback: (record: any | null) => any ): void {
	protected fetchRecordByID( requestID: string, cbToAcquireRecord: typeRequestForRecord, callback?: (record: any | null) => any ): void {
		// internal function -- the derived class should integrate itself to this, using it's own fetchSomethingByID().
		// this aggregates the callback for a record, with the rest of the callbacks for the same record.
		// this way, multiple callbacks piggy-back off of the same network request for a record.
		if ( !this.pendingRequestsByID.hasOwnProperty( requestID ) ) {
			this.pendingRequestsByID[requestID] = [];
		}
		if ( this.pendingRequestsByID[requestID].length > 0 ) {
			if ( typeof callback === 'function' ) {
				this.pendingRequestsByID[requestID].push( callback );
			}
		} else {
			const stackCookie = this.createStackCookie();
			this.stackCookies[requestID] = stackCookie;
			this.pendingRequestsByID[requestID].push( typeof callback === 'function' ? callback : this.stubFn );
			cbToAcquireRecord( (cbToProcessRecord: typeProcessFetchedRecord): void => {
				if ( this.stackCookies.hasOwnProperty( requestID ) && this.stackCookies[requestID] === stackCookie ) {
					// if true, then the stack cookie wasn't erased by a call to this.clear()
					// ...so that tells us that the fetch request wasn't a stale request.
					//
					// long-pending requests can resolve after somebody cleared the collection,
					// ...and I don't want components to respond to a (this collection).updated() event-emitter event anymore in that case.
					//
					// the collection should be cleared, i think, when workspaces are switched, or for sure when a user signs out.
					// imagine calling .clear() because of sign-out, and then the callback to process and cache records starts up later on...
					// the stack cookie logic is intended to stop things like that.
					this.stackCookies[requestID] = null;
					delete this.stackCookies[requestID];
					//
					cbToProcessRecord( (suppressUpdatedEvent: boolean | undefined): void => {
						this.resolvePendingRequest( requestID );
						if ( !suppressUpdatedEvent ) { // you can turn off the event emitter, in-case nothing was changed during record processing.
							this.updated.emit();
						}
					} );
				} // end if the stack cookie matches.
				// else do nothing
				// do not trigger the event emitter.
				// do not modify any records.
			} );
		}
	}

	protected fetchRecords( requestID: string, cbToAcquireRecords: typeRequestForRecord, callback?: (record: any | null) => any, filter?: (record: any) => boolean ): void {
		// internal function -- fetch bulk records with an optional filter
		if ( !this.pendingBulkRecordRequest.hasOwnProperty( requestID ) ) {
			this.pendingBulkRecordRequest[requestID] = [];
		}
		if ( this.pendingBulkRecordRequest[requestID].length > 0 ) {
			if ( typeof callback === 'function' ) {
				this.pendingBulkRecordRequest[requestID].push( {
					cb: callback,
					filter: typeof filter === 'function' ? filter: false
				} );
			} else {
				const stackCookie = this.createStackCookie();
				this.stackCookies[requestID] = stackCookie;
				this.pendingBulkRecordRequest[requestID].push( {
					cb: typeof callback === 'function' ? callback: this.stubFn,
					filter: typeof callback === 'function' && typeof filter === 'function' ? filter: false
				} );
				cbToAcquireRecords( (cbToProcessRecords: typeProcessFetchedRecord): void => {
					if ( this.stackCookies.hasOwnProperty( requestID ) && this.stackCookies[requestID] === stackCookie ) {
						this.stackCookies[requestID] = null;
						delete this.stackCookies[requestID];
						cbToProcessRecords( (suppressUpdatedEvent: boolean | undefined): void => {
							this.resolvePendingFilteredRequest( requestID );
							if ( !suppressUpdatedEvent ) {
								this.updated.emit();
							}
						} );
					}
				} );
			}
		}
	}
}
