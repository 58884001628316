import {Component, EventEmitter, Input, Output} from '@angular/core';
//
@Component({
	selector: 'ui-radio-group',
	templateUrl: './radio-group.html',
	styleUrls: [
		'./radio-group.less'
	]
})
export class ComponentRadioGroup {
	@Input() selectedIndex = 0;
	@Output() public selectedIndexChange: EventEmitter<number> = new EventEmitter<number>();
	@Input() public radioGroupValues: any[] = [];
	//
	constructor() {
		//
	}

	public setSelected( idx: number ): void {
		this.selectedIndex = idx < this.radioGroupValues.length ? idx : 0;
		this.selectedIndexChange.emit( this.selectedIndex );
	}
}
