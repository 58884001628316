import {Component, OnInit} from '@angular/core';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppEvents} from '../../app.events';
import {AppRouterLinks} from '../../app.router-links';
// ===== Interfaces ===== //
import {
	InterfaceHTTPGateway, InterfaceOWAPIResponse, InterfaceOWDoclet
} from '../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceNavigate} from '../../services/navigate';
import {ServiceOWAPI} from '../../services/ow-api';
//
@Component({
	selector: 'page-patients',
	templateUrl: './patients.html',
	styleUrls: [
		'./patients.less'
	]
})
export class PagePatients implements OnInit {
	public patients: InterfaceOWDoclet[] = [];
	public isSignedIn: boolean = false;
	//
	constructor(
		private appEvents: AppEvents,
		private auth: ServiceAuthentication,
		private config: AppConfig,
		private nav: ServiceNavigate,
		private owapi: ServiceOWAPI
	) {
		//
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			this.fetchPatients();
		} else {
			this.nav.toURL( AppRouterLinks.signIn );
		}
	}

	public trackByMRN( arrIdx: number, patient: InterfaceOWDoclet ): string {
		return patient.data['mrn']; // TODO: MRN + org/facility/workspace/etc
	}

	private fetchPatients(): void {
		let q = 'limit:50'; // TODO: skip (offset), limit, sort
		this.owapi.workspace.doclets.getDocletsByTemplateID( this.config.getTemplateID( 'Patient' ), q ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success ) {
				const apiResponse: InterfaceOWAPIResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
					this.patients = apiResponse.data.items;
				}
			}
		} );
	}
}
