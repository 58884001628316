import {Component} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.html',
  styleUrls: [
		'./app-footer.less'
	]
})
export class ComponentAppFooter {
	//
  constructor() {
		//
	}
}
