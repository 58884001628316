import {Injectable} from '@angular/core';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceSorting { // not sure if this qualifies as a Service file. Issue with naming conventions.
	//
	constructor(
		//
	) {
		//
	}

	public static naturalSort( A: any, B: any ): number {
		return String( A ).toLowerCase().localeCompare( String( B ).toLowerCase(), undefined, {
			numeric: true,
			sensitivity: 'base'
		} );
	}
}
