<header id="masthead">
	<div class="header">
		<h1>Patient Visits</h1>
		<ul class="actions nomobile">
			<li class="button">Export <i class="fa-solid fa-arrow-up"></i></li>
			<li class="button primary">Import CSV <i class="fa-solid fa-arrow-down"></i></li>
		</ul>
		<ul class="actions mobileonly">
			<li class="button"><i class="fa-solid fa-ellipsis"></i></li>
		</ul>
	</div>
	<ul class="tabs">
		<li class="active">Recent Visits</li>
		<li>All Visits</li>
	</ul>
</header>
<div class="data-table">
	<ul class="entries">
		<li class="row header">
			<!-- This would be a select-all. selected icon is <i class="fa-regular fa-square-check"></i> -->
			<span class="field select centered"><i class="fa-regular fa-square"></i></span>
			<span class="field">Date</span>
			<span class="field">Chief Complaint</span>
			<span class="field">Diagnosis</span>
			<span class="field actions"></span>
		</li>
		<li class="row" *ngFor="let record of visits; let idx = index; trackBy: trackByVisitID">
			<span class="mobileonly field header">Visit {{ getVisitID( record ) }}<span class="actions"><i class="fa-solid fa-ellipsis-vertical"></i></span></span>
			<span class="field select centered"><i class="fa-regular fa-square"></i></span>
			<span data-label="Date" class="field">{{ record.data['visit_date'] }}</span>
			<span data-label="Complaint" class="field">{{ record.data['chief_complaint'] }}</span>
			<span data-label="Diagnosis" class="field">{{ record.data['_icd10codes'] }}</span>
			<span data-label="Something" class="field actions"><i class="fa-solid fa-ellipsis-vertical"></i></span>
		</li>
	</ul>
</div>
