import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../../../app.config';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWAPIWorkspaceTasks {
	private routePrefix = 'workspace/';
	//
	constructor(
		private auth: ServiceAuthentication,
		private config: AppConfig,
		private gateway: ServiceOWGateway
	) {
		//
	}

	// ========================= Workflow Tasks ========================= //

	public getTasks(): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/tasks' );
	}

	public approveTask( taskId: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/task/' + encodeURIComponent( taskId ), JSON.stringify( {
			status: 'confirmed'
		} ) );
	}

	public denyTask( taskId: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'post', this.routePrefix + encodeURIComponent( this.config.getWorkspaceID() ) + '/task/' + encodeURIComponent( taskId ), JSON.stringify( {
			status: 'declined'
		} ) );
	}
}
