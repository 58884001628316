import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../../../app.config';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable({
	providedIn: 'root'
})
export class ServiceOWAPIWorkspaceTemplates { // templates belong in workspaces because they cannot stand alone. templates have to be assigned to a workspace ID or they won't function.
	private routePrefix = 'template/';
	//
	constructor(
		private auth: ServiceAuthentication,
		private config: AppConfig,
		private gateway: ServiceOWGateway
	) {
		//
	}

	// ========================= Templates ========================= //

	public getTemplates(): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', 'workspace/' + encodeURIComponent( this.config.getWorkspaceID() ) + '/templates' );
	}

	public getTemplateByID( templateId: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', 'workspace/' + encodeURIComponent( this.config.getWorkspaceID() ) + '/template/' + encodeURIComponent( templateId ) );
	}

	public getTemplateMeta( templateId: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.requestDenied(); // TODO: figure out if this is already included when you fetch a template or not.
	}

	// ========================= Weaves ========================= //

	public getWeaveSchema( template: string ): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + encodeURIComponent( template ) + '/weaves' );
	}
}
